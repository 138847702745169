// src/icons/turn.arrow.svg
import * as React from "react";
import { jsx, jsxs } from "react/jsx-runtime";
var SvgTurnarrow = ({ title, titleId, ...props }) => /* @__PURE__ */ jsxs(
  "svg",
  {
    width: "1em",
    height: "1em",
    viewBox: "0 0 24 24",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    "aria-labelledby": titleId,
    ...props,
    children: [
      title ? /* @__PURE__ */ jsx("title", { id: titleId, children: title }) : null,
      /* @__PURE__ */ jsx(
        "path",
        {
          d: "m19.28 15.97-3.75-3.75a.75.75 0 0 0-1.06 1.06l2.47 2.47H8.25A2.25 2.25 0 0 1 6 13.5V3.75a.75.75 0 0 0-1.5 0v9.75a3.754 3.754 0 0 0 3.75 3.75h8.69l-2.47 2.47a.75.75 0 1 0 1.06 1.06l3.75-3.75a.75.75 0 0 0 0-1.06Z",
          fill: "currentColor"
        }
      )
    ]
  }
);

export {
  SvgTurnarrow
};
