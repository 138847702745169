// src/icons/credit-card.svg
import * as React from "react";
import { jsx, jsxs } from "react/jsx-runtime";
var SvgCreditCard = ({ title, titleId, ...props }) => /* @__PURE__ */ jsxs(
  "svg",
  {
    width: "1em",
    height: "1em",
    viewBox: "0 0 24 24",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    "aria-labelledby": titleId,
    ...props,
    children: [
      title ? /* @__PURE__ */ jsx("title", { id: titleId, children: title }) : null,
      /* @__PURE__ */ jsx(
        "path",
        {
          d: "M22 6a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V6Zm-2 2H4V6h16v2ZM4 11h16v7H4v-7Z",
          fill: "currentColor"
        }
      )
    ]
  }
);

export {
  SvgCreditCard
};
