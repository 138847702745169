// src/icons/warning.svg
import * as React from "react";
import { jsx, jsxs } from "react/jsx-runtime";
var SvgWarning = ({ title, titleId, ...props }) => /* @__PURE__ */ jsxs(
  "svg",
  {
    width: "1em",
    height: "1em",
    viewBox: "0 0 24 24",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    "aria-labelledby": titleId,
    ...props,
    children: [
      title ? /* @__PURE__ */ jsx("title", { id: titleId, children: title }) : null,
      /* @__PURE__ */ jsx(
        "path",
        {
          d: "M12 14a1 1 0 0 1-1-1v-3a1 1 0 1 1 2 0v3a1 1 0 0 1-1 1ZM10.5 16.5a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0Z",
          fill: "currentColor"
        }
      ),
      /* @__PURE__ */ jsx(
        "path",
        {
          d: "M10.23 3.215c.75-1.424 2.79-1.424 3.54 0l8.344 15.853c.7 1.332-.265 2.932-1.77 2.932H3.657c-1.505 0-2.471-1.6-1.77-2.932L10.23 3.215ZM20.344 20 12 4.147 3.657 20h16.687Z",
          fill: "currentColor"
        }
      )
    ]
  }
);

export {
  SvgWarning
};
