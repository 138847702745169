// src/icons/caret-left.svg
import * as React from "react";
import { jsx, jsxs } from "react/jsx-runtime";
var SvgCaretLeft = ({ title, titleId, ...props }) => /* @__PURE__ */ jsxs(
  "svg",
  {
    width: "1em",
    height: "1em",
    viewBox: "0 0 24 24",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    "aria-labelledby": titleId,
    ...props,
    children: [
      title ? /* @__PURE__ */ jsx("title", { id: titleId, children: title }) : null,
      /* @__PURE__ */ jsx("path", { d: "m14 17-6-5 6-5v10Z", fill: "currentColor" })
    ]
  }
);

export {
  SvgCaretLeft
};
