// src/icons/attachment.svg
import * as React from "react";
import { jsx, jsxs } from "react/jsx-runtime";
var SvgAttachment = ({ title, titleId, ...props }) => /* @__PURE__ */ jsxs(
  "svg",
  {
    width: "1em",
    height: "1em",
    viewBox: "0 0 24 24",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    "aria-labelledby": titleId,
    ...props,
    children: [
      title ? /* @__PURE__ */ jsx("title", { id: titleId, children: title }) : null,
      /* @__PURE__ */ jsx(
        "path",
        {
          d: "M19.463 5.576c-.687-.75-1.928-.796-2.756.031l-8.1 8.1c-.21.21-.21.476 0 .686.21.21.477.21.686 0l6.7-6.7a1 1 0 1 1 1.414 1.414l-6.7 6.7a2.45 2.45 0 0 1-3.514 0 2.45 2.45 0 0 1 0-3.514l8.1-8.1c1.568-1.568 4.115-1.619 5.63.015 1.552 1.569 1.598 4.104-.03 5.613l-9.486 9.486c-2.19 2.19-5.623 2.19-7.814 0-2.19-2.19-2.19-5.624 0-7.814l8.1-8.1a1 1 0 1 1 1.414 1.414l-8.1 8.1c-1.41 1.41-1.41 3.576 0 4.986 1.41 1.41 3.577 1.41 4.986 0l9.5-9.5.032-.03c.75-.687.795-1.929-.032-2.756a.947.947 0 0 1-.03-.031Z",
          fill: "currentColor"
        }
      )
    ]
  }
);

export {
  SvgAttachment
};
