// src/icons/filter.svg
import * as React from "react";
import { jsx, jsxs } from "react/jsx-runtime";
var SvgFilter = ({ title, titleId, ...props }) => /* @__PURE__ */ jsxs(
  "svg",
  {
    width: "1em",
    height: "1em",
    viewBox: "0 0 24 24",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    "aria-labelledby": titleId,
    ...props,
    children: [
      title ? /* @__PURE__ */ jsx("title", { id: titleId, children: title }) : null,
      /* @__PURE__ */ jsx(
        "path",
        {
          d: "M9 5a1 1 0 1 0 0 2 1 1 0 0 0 0-2ZM6.17 5a3.001 3.001 0 0 1 5.66 0H19a1 1 0 1 1 0 2h-7.17a3.001 3.001 0 0 1-5.66 0H5a1 1 0 0 1 0-2h1.17ZM15 11a1 1 0 1 0 0 2 1 1 0 0 0 0-2Zm-2.83 0a3.001 3.001 0 0 1 5.66 0H19a1 1 0 1 1 0 2h-1.17a3.001 3.001 0 0 1-5.66 0H5a1 1 0 1 1 0-2h7.17ZM9 17a1 1 0 1 0 0 2 1 1 0 0 0 0-2Zm-2.83 0a3.001 3.001 0 0 1 5.66 0H19a1 1 0 1 1 0 2h-7.17a3.001 3.001 0 0 1-5.66 0H5a1 1 0 1 1 0-2h1.17Z",
          fill: "currentColor"
        }
      )
    ]
  }
);

export {
  SvgFilter
};
