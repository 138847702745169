// src/icons/call.svg
import * as React from "react";
import { jsx, jsxs } from "react/jsx-runtime";
var SvgCall = ({ title, titleId, ...props }) => /* @__PURE__ */ jsxs(
  "svg",
  {
    width: "1em",
    height: "1em",
    viewBox: "0 0 24 24",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    "aria-labelledby": titleId,
    ...props,
    children: [
      title ? /* @__PURE__ */ jsx("title", { id: titleId, children: title }) : null,
      /* @__PURE__ */ jsx(
        "path",
        {
          d: "M3.833 4h4.49L9.77 7.618l-2.325 1.55A1 1 0 0 0 7 10c.003.094 0 .001 0 .001V10.022a2.129 2.129 0 0 0 .006.134c.006.082.016.193.035.33.04.27.114.642.26 1.08.294.88.87 2.019 1.992 3.141 1.122 1.122 2.261 1.698 3.14 1.992.44.146.81.22 1.082.26a4.424 4.424 0 0 0 .463.04l.013.001h.008s.112-.006.001 0a1 1 0 0 0 .895-.553l.67-1.34 4.435.74v4.32c-2.11.305-7.812.606-12.293-3.874C3.227 11.813 3.527 6.11 3.833 4Zm5.24 6.486 1.807-1.204a2 2 0 0 0 .747-2.407L10.18 3.257A2 2 0 0 0 8.323 2H3.781c-.909 0-1.764.631-1.913 1.617-.34 2.242-.801 8.864 4.425 14.09 5.226 5.226 11.848 4.764 14.09 4.425.986-.15 1.617-1.004 1.617-1.913v-4.372a2 2 0 0 0-1.671-1.973l-4.436-.739a2 2 0 0 0-2.118 1.078l-.346.693a4.709 4.709 0 0 1-.363-.105c-.62-.206-1.48-.63-2.359-1.508-.878-.878-1.301-1.739-1.508-2.36a4.586 4.586 0 0 1-.125-.447Z",
          fill: "currentColor"
        }
      )
    ]
  }
);

export {
  SvgCall
};
