// src/icons/edit.svg
import * as React from "react";
import { jsx, jsxs } from "react/jsx-runtime";
var SvgEdit = ({ title, titleId, ...props }) => /* @__PURE__ */ jsxs(
  "svg",
  {
    width: "1em",
    height: "1em",
    viewBox: "0 0 24 24",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    "aria-labelledby": titleId,
    ...props,
    children: [
      title ? /* @__PURE__ */ jsx("title", { id: titleId, children: title }) : null,
      /* @__PURE__ */ jsx(
        "path",
        {
          d: "M16.293 2.293a1 1 0 0 1 1.414 0l4 4a1 1 0 0 1 0 1.414l-13 13A1 1 0 0 1 8 21H4a1 1 0 0 1-1-1v-4a1 1 0 0 1 .293-.707l10-10 3-3ZM14 7.414l-9 9V19h2.586l9-9L14 7.414Zm4 1.172L19.586 7 17 4.414 15.414 6 18 8.586Z",
          fill: "currentColor"
        }
      )
    ]
  }
);

export {
  SvgEdit
};
