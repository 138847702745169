// src/icons/icon.youtube.svg
import * as React from "react";
import { jsx, jsxs } from "react/jsx-runtime";
var SvgIconyoutube = ({ title, titleId, ...props }) => /* @__PURE__ */ jsxs(
  "svg",
  {
    width: "1em",
    height: "1em",
    viewBox: "0 0 24 24",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    "aria-labelledby": titleId,
    ...props,
    children: [
      title ? /* @__PURE__ */ jsx("title", { id: titleId, children: title }) : null,
      /* @__PURE__ */ jsx(
        "path",
        {
          d: "M20.884 8.23c0-1.581-1.163-2.854-2.602-2.854a127.895 127.895 0 0 0-5.964-.126h-.633c-2.025 0-4.015.035-5.963.127-1.435 0-2.599 1.28-2.599 2.861A52.283 52.283 0 0 0 3 11.992a54.43 54.43 0 0 0 .12 3.758c0 1.582 1.164 2.866 2.598 2.866 2.046.095 4.146.137 6.28.133 2.138.007 4.23-.037 6.28-.133 1.438 0 2.601-1.284 2.601-2.865.085-1.255.123-2.507.12-3.762a51.133 51.133 0 0 0-.115-3.758Zm-10.606 7.211V8.533l5.099 3.452-5.099 3.456Z",
          fill: "currentColor"
        }
      )
    ]
  }
);

export {
  SvgIconyoutube
};
