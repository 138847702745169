// src/icons/icon.tiktok.svg
import * as React from "react";
import { jsx, jsxs } from "react/jsx-runtime";
var SvgIcontiktok = ({ title, titleId, ...props }) => /* @__PURE__ */ jsxs(
  "svg",
  {
    width: "1em",
    height: "1em",
    viewBox: "0 0 24 24",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    "aria-labelledby": titleId,
    ...props,
    children: [
      title ? /* @__PURE__ */ jsx("title", { id: titleId, children: title }) : null,
      /* @__PURE__ */ jsx(
        "path",
        {
          d: "M17.632 6.85a3.908 3.908 0 0 1-.34-.207c-.321-.22-.615-.48-.876-.773a4.37 4.37 0 0 1-.986-2.116h.004c-.075-.458-.044-.754-.04-.754h-2.972v11.954c0 .16 0 .319-.006.476l-.003.058c0 .009 0 .018-.002.027v.006a2.697 2.697 0 0 1-.385 1.207 2.57 2.57 0 0 1-.885.877 2.408 2.408 0 0 1-1.23.337c-1.384 0-2.507-1.174-2.507-2.625 0-1.45 1.123-2.624 2.507-2.624a2.4 2.4 0 0 1 .772.127l.004-3.148a5.317 5.317 0 0 0-2.258.184 5.456 5.456 0 0 0-1.996 1.11A6.026 6.026 0 0 0 5.16 12.6c-.126.225-.599 1.129-.656 2.596-.036.833.204 1.696.319 2.052v.008c.072.21.351.927.807 1.53.367.485.8.91 1.287 1.264v-.008l.007.008c1.44 1.017 3.035.95 3.035.95.276-.011 1.201 0 2.252-.518 1.165-.574 1.828-1.43 1.828-1.43a5.99 5.99 0 0 0 .997-1.721 6.685 6.685 0 0 0 .359-1.97V9.018c.036.023.516.353.516.353s.692.461 1.771.761c.775.214 1.818.26 1.818.26v-3.07c-.366.042-1.108-.078-1.868-.472Z",
          fill: "currentColor"
        }
      )
    ]
  }
);

export {
  SvgIcontiktok
};
