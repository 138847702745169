// src/icons/rotate.svg
import * as React from "react";
import { jsx, jsxs } from "react/jsx-runtime";
var SvgRotate = ({ title, titleId, ...props }) => /* @__PURE__ */ jsxs(
  "svg",
  {
    width: "1em",
    height: "1em",
    viewBox: "0 0 24 24",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    "aria-labelledby": titleId,
    ...props,
    children: [
      title ? /* @__PURE__ */ jsx("title", { id: titleId, children: title }) : null,
      /* @__PURE__ */ jsx(
        "path",
        {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d: "M3 1a1 1 0 0 1 1 1v5h5a1 1 0 1 1 0 2H3a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1Z",
          fill: "currentColor"
        }
      ),
      /* @__PURE__ */ jsx(
        "path",
        {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d: "M11.389 2.068a10 10 0 1 1-9.383 11.037 1 1 0 0 1 1.989-.21 8 8 0 1 0 2.67-6.848L3.669 8.743a1 1 0 0 1-1.338-1.486L5.34 4.55a10 10 0 0 1 6.05-2.482Z",
          fill: "currentColor"
        }
      ),
      /* @__PURE__ */ jsx(
        "path",
        {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d: "M13.374 8a.632.632 0 0 0-.627.638v5.086h-3.12a.632.632 0 0 0-.627.638c0 .352.28.638.626.638h3.748a.632.632 0 0 0 .626-.638V8.638A.632.632 0 0 0 13.374 8Z",
          fill: "currentColor"
        }
      )
    ]
  }
);

export {
  SvgRotate
};
