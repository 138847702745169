// src/icons/icon.facebook.svg
import * as React from "react";
import { jsx, jsxs } from "react/jsx-runtime";
var SvgIconfacebook = ({ title, titleId, ...props }) => /* @__PURE__ */ jsxs(
  "svg",
  {
    width: "1em",
    height: "1em",
    viewBox: "0 0 24 24",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    "aria-labelledby": titleId,
    ...props,
    children: [
      title ? /* @__PURE__ */ jsx("title", { id: titleId, children: title }) : null,
      /* @__PURE__ */ jsx(
        "path",
        {
          d: "M20.01 3H3.99a.99.99 0 0 0-.99.99v16.02a.99.99 0 0 0 .99.99h8.622v-6.975h-2.34v-2.7h2.34V9.3a3.277 3.277 0 0 1 3.492-3.6c.7-.004 1.4.032 2.097.108v2.43H16.77c-1.134 0-1.35.54-1.35 1.323v1.737h2.7l-.351 2.7H15.42V21h4.59a.99.99 0 0 0 .99-.99V3.99a.99.99 0 0 0-.99-.99Z",
          fill: "currentColor"
        }
      )
    ]
  }
);

export {
  SvgIconfacebook
};
