// src/icons/icon.snapchat.svg
import * as React from "react";
import { jsx, jsxs } from "react/jsx-runtime";
var SvgIconsnapchat = ({ title, titleId, ...props }) => /* @__PURE__ */ jsxs(
  "svg",
  {
    width: "1em",
    height: "1em",
    viewBox: "0 0 24 24",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    "aria-labelledby": titleId,
    ...props,
    children: [
      title ? /* @__PURE__ */ jsx("title", { id: titleId, children: title }) : null,
      /* @__PURE__ */ jsx(
        "path",
        {
          d: "M21.75 15.664a7.81 7.81 0 0 1-1.332-.214c-1.108-.266-2.228-.974-2.767-2.106-.28-.588-.108-.747.481-.964.576-.212 1.211-.31 1.498-.924.24-.513.046-1.116-.423-1.426-.638-.423-1.233-.009-1.894.083.152-1.875.352-3.558-.921-5.132C15.337 3.677 13.679 3 12.012 3c-1.668 0-3.326.677-4.38 1.982-1.275 1.577-1.072 3.261-.921 5.139-.663-.09-1.252-.514-1.894-.088a1.141 1.141 0 0 0-.221 1.728c.47.532 1.285.382 1.765.828.264.244.142.506-.014.809-.589 1.14-1.595 1.837-2.838 2.071-.162.03-.906.115-1.259.147 0 .373.021.674.066.873.12.524.5.947.963 1.209.454.257 1.441.258 1.693.623.122.18.071.493.216.698a.968.968 0 0 0 .625.392c.756.145 1.516-.29 2.292-.084.603.158 1.077.637 1.595.964.63.396 1.314.66 2.065.703 1.548.088 2.434-.76 3.68-1.464.792-.447 1.55-.155 2.392-.107.816.046.956-.372 1.203-1 .027-.063.048-.128.063-.195a.43.43 0 0 0 .092-.059c.081-.063.429-.094.518-.115.418-.098.827-.206 1.185-.45.46-.311.718-.743.796-1.31.036-.209.055-.42.056-.63Zm-11.7-8.807c.36 0 .65.431.65.964 0 .533-.29.964-.65.964-.36 0-.65-.431-.65-.964 0-.533.29-.964.65-.964Zm4.21 3.121a3.235 3.235 0 0 1-2.26.937 3.214 3.214 0 0 1-2.261-.938.32.32 0 0 1 .23-.55c.086 0 .169.035.23.095a2.538 2.538 0 0 0 1.801.75 2.58 2.58 0 0 0 1.803-.752.327.327 0 0 1 .46.004.32.32 0 0 1-.003.454Zm-.31-1.193c-.36 0-.65-.432-.65-.964s.29-.964.65-.964c.36 0 .65.432.65.964s-.29.964-.65.964Z",
          fill: "currentColor"
        }
      )
    ]
  }
);

export {
  SvgIconsnapchat
};
