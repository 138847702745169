// src/icons/icon.linkedin.svg
import * as React from "react";
import { jsx, jsxs } from "react/jsx-runtime";
var SvgIconlinkedin = ({ title, titleId, ...props }) => /* @__PURE__ */ jsxs(
  "svg",
  {
    width: "1em",
    height: "1em",
    viewBox: "0 0 24 24",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    "aria-labelledby": titleId,
    ...props,
    children: [
      title ? /* @__PURE__ */ jsx("title", { id: titleId, children: title }) : null,
      /* @__PURE__ */ jsx(
        "path",
        {
          d: "M19.56 3H4.538C3.718 3 3 3.59 3 4.402v15.055C3 20.273 3.717 21 4.538 21h15.018c.825 0 1.444-.732 1.444-1.543V4.402C21.005 3.59 20.381 3 19.56 3ZM8.58 18.004H6V9.986h2.58v8.018Zm-1.2-9.237H7.36c-.825 0-1.36-.614-1.36-1.383C6.001 6.6 6.55 6 7.394 6c.843 0 1.36.595 1.378 1.383 0 .769-.535 1.383-1.393 1.383Zm10.624 9.237h-2.579V13.62c0-1.05-.375-1.768-1.308-1.768-.713 0-1.135.482-1.322.952-.07.169-.09.399-.09.633v4.567h-2.578V9.986h2.579v1.116c.375-.534.961-1.303 2.325-1.303 1.693 0 2.973 1.115 2.973 3.52v4.685Z",
          fill: "currentColor"
        }
      )
    ]
  }
);

export {
  SvgIconlinkedin
};
