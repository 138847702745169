// src/icons/tag.svg
import * as React from "react";
import { jsx, jsxs } from "react/jsx-runtime";
var SvgTag = ({ title, titleId, ...props }) => /* @__PURE__ */ jsxs(
  "svg",
  {
    width: "1em",
    height: "1em",
    viewBox: "0 0 24 24",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    "aria-labelledby": titleId,
    ...props,
    children: [
      title ? /* @__PURE__ */ jsx("title", { id: titleId, children: title }) : null,
      /* @__PURE__ */ jsx(
        "path",
        {
          d: "M2 3a1 1 0 0 1 1-1h8a1 1 0 0 1 .707.293l10 10a1 1 0 0 1 0 1.414l-8 8a1 1 0 0 1-1.414 0l-10-10A1 1 0 0 1 2 11V3Zm2 1v6.586l9 9L19.586 13l-9-9H4Z",
          fill: "currentColor"
        }
      ),
      /* @__PURE__ */ jsx("path", { d: "M9 7.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z", fill: "currentColor" })
    ]
  }
);

export {
  SvgTag
};
