// src/icons/arrow-right.svg
import * as React from "react";
import { jsx, jsxs } from "react/jsx-runtime";
var SvgArrowRight = ({ title, titleId, ...props }) => /* @__PURE__ */ jsxs(
  "svg",
  {
    width: "1em",
    height: "1em",
    viewBox: "0 0 24 24",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    "aria-labelledby": titleId,
    ...props,
    children: [
      title ? /* @__PURE__ */ jsx("title", { id: titleId, children: title }) : null,
      /* @__PURE__ */ jsx(
        "path",
        {
          d: "M12.293 5.293a1 1 0 0 1 1.414 0l6 6a1 1 0 0 1 0 1.414l-6 6a1 1 0 0 1-1.414-1.414L16.586 13H5a1 1 0 1 1 0-2h11.586l-4.293-4.293a1 1 0 0 1 0-1.414Z",
          fill: "currentColor"
        }
      )
    ]
  }
);

export {
  SvgArrowRight
};
