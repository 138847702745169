// src/icons/icon.twitter.svg
import * as React from "react";
import { jsx, jsxs } from "react/jsx-runtime";
var SvgIcontwitter = ({ title, titleId, ...props }) => /* @__PURE__ */ jsxs(
  "svg",
  {
    width: "1em",
    height: "1em",
    viewBox: "0 0 24 24",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    "aria-labelledby": titleId,
    ...props,
    children: [
      title ? /* @__PURE__ */ jsx("title", { id: titleId, children: title }) : null,
      /* @__PURE__ */ jsx(
        "path",
        {
          d: "M21 6.85a7.968 7.968 0 0 1-2.12.538 3.482 3.482 0 0 0 1.624-1.885 7.728 7.728 0 0 1-2.346.826 3.733 3.733 0 0 0-1.23-.8 3.932 3.932 0 0 0-1.466-.279c-2.041 0-3.693 1.526-3.693 3.407-.001.261.03.522.096.777a11.086 11.086 0 0 1-4.208-1.028 10.445 10.445 0 0 1-3.405-2.534 3.206 3.206 0 0 0-.502 1.712c0 1.182.657 2.226 1.65 2.838a3.846 3.846 0 0 1-1.68-.426v.042c0 1.653 1.276 3.027 2.963 3.34-.317.08-.644.12-.973.12-.233 0-.465-.021-.694-.064.47 1.354 1.835 2.338 3.452 2.366a7.813 7.813 0 0 1-4.587 1.46c-.294-.001-.589-.018-.881-.05a10.966 10.966 0 0 0 5.653 1.54c6.8 0 10.516-5.193 10.516-9.696 0-.148-.004-.295-.011-.44A7.213 7.213 0 0 0 21 6.85Z",
          fill: "currentColor"
        }
      )
    ]
  }
);

export {
  SvgIcontwitter
};
