// src/icons/history.svg
import * as React from "react";
import { jsx, jsxs } from "react/jsx-runtime";
var SvgHistory = ({ title, titleId, ...props }) => /* @__PURE__ */ jsxs(
  "svg",
  {
    width: "1em",
    height: "1em",
    viewBox: "0 0 24 24",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    "aria-labelledby": titleId,
    ...props,
    children: [
      title ? /* @__PURE__ */ jsx("title", { id: titleId, children: title }) : null,
      /* @__PURE__ */ jsx(
        "path",
        {
          d: "M12.207 2.293a1 1 0 0 1 0 1.414L10.914 5H12.5c4.652 0 8.5 3.848 8.5 8.5S17.152 22 12.5 22 4 18.152 4 13.5a1 1 0 0 1 2 0c0 3.548 2.952 6.5 6.5 6.5s6.5-2.952 6.5-6.5S16.048 7 12.5 7h-1.586l1.293 1.293a1 1 0 0 1-1.414 1.414l-3-3a1 1 0 0 1 0-1.414l3-3a1 1 0 0 1 1.414 0Z",
          fill: "currentColor"
        }
      ),
      /* @__PURE__ */ jsx(
        "path",
        {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d: "M13.5 11c-.278 0-.502.245-.502.547v3.36h-2.497c-.276 0-.5.244-.5.546 0 .302.224.547.5.547H13.5c.277 0 .501-.245.501-.547v-3.906c0-.302-.224-.547-.5-.547Z",
          fill: "currentColor"
        }
      ),
      /* @__PURE__ */ jsx(
        "path",
        {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d: "M14.3 11.547c0-.443-.334-.847-.8-.847-.467 0-.802.404-.802.847v3.06h-2.197c-.466 0-.8.404-.8.846 0 .443.334.847.8.847H13.5c.467 0 .801-.404.801-.847v-3.906Zm-.8-.247c.086 0 .2.085.2.247v3.906c0 .162-.114.247-.2.247H10.5c-.087 0-.2-.085-.2-.247 0-.161.113-.247.2-.247h2.497a.3.3 0 0 0 .3-.3v-3.36c0-.16.114-.246.201-.246Z",
          fill: "currentColor"
        }
      )
    ]
  }
);

export {
  SvgHistory
};
