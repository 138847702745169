// src/icons/mobile.svg
import * as React from "react";
import { jsx, jsxs } from "react/jsx-runtime";
var SvgMobile = ({ title, titleId, ...props }) => /* @__PURE__ */ jsxs(
  "svg",
  {
    width: "1em",
    height: "1em",
    viewBox: "0 0 24 24",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    "aria-labelledby": titleId,
    ...props,
    children: [
      title ? /* @__PURE__ */ jsx("title", { id: titleId, children: title }) : null,
      /* @__PURE__ */ jsx(
        "path",
        {
          d: "M6 5a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H8a2 2 0 0 1-2-2V5Zm10 0H8v14h8V5Z",
          fill: "currentColor"
        }
      ),
      /* @__PURE__ */ jsx("path", { d: "M13 17a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z", fill: "currentColor" })
    ]
  }
);

export {
  SvgMobile
};
