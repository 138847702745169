// scripts/react-shim.ts
import * as React from "react";

// src/components/accordion/Accordion.tsx
import { cloneElement, isValidElement } from "react";
import clsx from "clsx";

// src/components/accordion/AccordionContext.tsx
import { createContext, useId, useReducer, useContext } from "react";
import { jsx } from "react/jsx-runtime";
var initialState = {
  current: [],
  multiple: false,
  id: ""
};
var AccordionContext = createContext({});
function AccordionReducer(state = initialState, { type, payload }) {
  switch (type) {
    case "toggle": {
      const nextCurrent = state.multiple ? state.current.includes(payload) ? state.current.filter((c) => c !== payload) : [...state.current, payload] : state.current.includes(payload) ? [] : [payload];
      return {
        ...state,
        current: nextCurrent
      };
    }
    default:
      throw new Error(`Unhandled action type: ${type}`);
  }
}
function AccordionProvider({
  initial,
  multiple = false,
  children
}) {
  const id = useId();
  const [state, dispatch] = useReducer(AccordionReducer, {
    ...initialState,
    id,
    multiple,
    current: initial ? initial : []
  });
  return /* @__PURE__ */ jsx(AccordionContext.Provider, { value: { state, dispatch }, children });
}
function useAccordion() {
  const context = useContext(AccordionContext);
  if (context === void 0) {
    throw new Error("useAccordion must be used within a AccordionProvider");
  }
  return context;
}

// src/components/accordion/Accordion.tsx
import { jsx as jsx2, jsxs } from "react/jsx-runtime";
function Accordion({
  initial,
  level = 1,
  multiple,
  children
}) {
  return /* @__PURE__ */ jsx2(AccordionProvider, { initial, multiple, children: /* @__PURE__ */ jsxs(
    "section",
    {
      className: clsx("divide-y-1", "divide-neutral-40", {
        ["ml-4"]: level === 2,
        ["ml-8"]: level === 3,
        ["ml-12"]: level === 4
      }),
      children: [
        isValidElement(children) && cloneElement(children, { level }),
        Array.isArray(children) && children.map(
          (child) => isValidElement(child) && cloneElement(child, { level })
        )
      ]
    }
  ) });
}

// src/components/accordion/AccordionContent.tsx
import clsx2 from "clsx";
import slugify from "slugify";
import Add from "@fu/icons/Add";
import Minus from "@fu/icons/Minus";
import { jsx as jsx3, jsxs as jsxs2 } from "react/jsx-runtime";
function AccordionContent({
  children,
  className,
  ...props
}) {
  const {
    state: { current, id },
    dispatch
  } = useAccordion();
  const { label, level = 1 } = props;
  const isOpen = current.includes(label);
  const labelSlug = slugify(label);
  const headerId = `${id}_header_${labelSlug}`;
  const panelId = `${id}_panel_${labelSlug}`;
  const cssClass = clsx2(isOpen && "pb-4", className);
  const cssButtonClass = clsx2(
    "flex w-full justify-between items-center pt-4",
    isOpen && "pb-2",
    !isOpen && "pb-4"
  );
  const cssClassContent = clsx2(!isOpen && "hidden");
  const handleToggle = () => {
    dispatch({
      type: "toggle",
      payload: label
    });
  };
  const iconClass = clsx2("ml-2 w-2.5 h-2.5 sm:w-4 sm:h-4");
  return /* @__PURE__ */ jsxs2("div", { ...props, className: cssClass, children: [
    /* @__PURE__ */ jsxs2(
      "button",
      {
        id: headerId,
        className: cssButtonClass,
        onClick: handleToggle,
        "aria-controls": panelId,
        "aria-expanded": isOpen,
        children: [
          /* @__PURE__ */ jsx3(
            "span",
            {
              className: clsx2(
                "inline-block",
                "text-left",
                { ["hd-md sm:hd-xl"]: level === 1 },
                { ["hd-md sm:hd-lg"]: level === 2 },
                { ["hd-sm sm:hd-md"]: level === 3 }
              ),
              children: label
            }
          ),
          isOpen ? /* @__PURE__ */ jsx3(Minus, { "aria-hidden": true, className: iconClass }) : /* @__PURE__ */ jsx3(Add, { "aria-hidden": true, className: iconClass })
        ]
      }
    ),
    /* @__PURE__ */ jsx3(
      "section",
      {
        id: panelId,
        className: cssClassContent,
        "aria-labelledby": headerId,
        children
      }
    )
  ] });
}

// src/components/alert/Alert.tsx
import { forwardRef } from "react";
import clsx3 from "clsx";
import CircleCheck from "@fu/icons/CircleCheck";
import CircleInformation from "@fu/icons/CircleInformation";
import Warning from "@fu/icons/Warning";
import CircleWarning from "@fu/icons/CircleWarning";
import { jsx as jsx4, jsxs as jsxs3 } from "react/jsx-runtime";
var Alert = forwardRef(function Alert2({
  type = "info",
  message,
  description,
  inline = false,
  badge = false,
  interactive = false,
  className,
  ...props
}, ref) {
  const cssClass = clsx3(
    "flex rounded",
    {
      ["py-3"]: !badge,
      ["px-4"]: !inline && !badge,
      ["p-1.5"]: badge,
      ["border"]: !inline || badge,
      ["border-success-icon bg-success-bg"]: type === "success" && (!inline || badge),
      ["border-info-icon bg-info-bg"]: type === "info" && (!inline || badge),
      ["border-warning-icon bg-warning-bg"]: type === "warning" && (!inline || badge),
      ["border-error-icon bg-error-bg"]: type === "error" && (!inline || badge),
      ["text-success-text"]: type === "success",
      ["text-info-text"]: type === "info",
      ["text-warning-text"]: type === "warning",
      ["text-error-text"]: type === "error"
    },
    className
  );
  const iconSize = badge ? "w-4 h-4" : "w-5 h-5";
  const icons = {
    success: /* @__PURE__ */ jsx4(CircleCheck, { className: clsx3(`text-success-icon ${iconSize}`) }),
    info: /* @__PURE__ */ jsx4(CircleInformation, { className: clsx3(`text-info-icon ${iconSize}`) }),
    warning: /* @__PURE__ */ jsx4(Warning, { className: clsx3(`text-warning-icon ${iconSize}`) }),
    error: /* @__PURE__ */ jsx4(CircleWarning, { className: clsx3(`text-error-icon ${iconSize}`) })
  };
  const centered = ["flex", "justify-center", "items-center"].join(" ");
  return /* @__PURE__ */ jsxs3(
    "div",
    {
      className: cssClass,
      ref,
      ...props,
      children: [
        /* @__PURE__ */ jsx4(
          "span",
          {
            className: clsx3(centered, badge ? "h-4" : "h-5", {
              "mr-2": inline || badge || !description,
              "mr-5": !inline && !badge && description
            }),
            children: icons[type] || null
          }
        ),
        /* @__PURE__ */ jsxs3(
          "div",
          {
            className: clsx3({
              [centered]: inline || badge || !description
            }),
            children: [
              /* @__PURE__ */ jsx4("p", { className: clsx3({ "cap-sm": !badge, "cap-xs": badge }), children: message }),
              !inline && !badge && description && /* @__PURE__ */ jsx4("p", { className: "body-xs mt-2", children: description })
            ]
          }
        )
      ]
    }
  );
});

// src/components/anchor/Anchor.tsx
import { forwardRef as forwardRef2, isValidElement as isValidElement2, cloneElement as cloneElement2 } from "react";
import clsx4 from "clsx";
import ArrowLeft from "@fu/icons/ArrowLeft";
import ArrowRight from "@fu/icons/ArrowRight";
import { jsx as jsx5, jsxs as jsxs4 } from "react/jsx-runtime";
var Anchor = forwardRef2(function Anchor2({
  size,
  disabled = false,
  disabledReason,
  iconLeft,
  iconRight,
  variant = "default",
  onClick,
  children,
  className,
  ...props
}, ref) {
  const iconLeftClass = clsx4("inline-block", {
    "mr-1.5": children || iconRight
  });
  const iconRightClass = clsx4("inline-block", {
    "ml-1.5": children || iconRight
  });
  return /* @__PURE__ */ jsx5(
    "a",
    {
      ref,
      className: clsx4(
        "inline-flex",
        "cursor-pointer",
        "items-center",
        "hover:no-underline",
        {
          ["cap-link-sm"]: size === "sm",
          ["cap-link-base"]: size === "base",
          ["cap-link-md"]: size === "md",
          ["cap-link-lg"]: size === "lg",
          ["underline"]: size === void 0
        },
        variant === "default" && "text-neutral-70 hover:text-primary-60",
        variant === "white" && "text-white",
        variant === "current" && "text-current",
        className
      ),
      ...props,
      children: /* @__PURE__ */ jsxs4("span", { children: [
        iconLeft === "arrow-left" && /* @__PURE__ */ jsx5(
          ArrowLeft,
          {
            width: "0.875em",
            height: "0.875em",
            className: iconLeftClass
          }
        ),
        typeof iconLeft !== "string" && isValidElement2(iconLeft) && cloneElement2(iconLeft, {
          title: "",
          className: iconLeftClass
        }),
        children,
        "arrow-right" === iconRight && /* @__PURE__ */ jsx5(
          ArrowRight,
          {
            width: "0.875em",
            height: "0.875em",
            className: iconRightClass
          }
        ),
        typeof iconRight !== "string" && isValidElement2(iconRight) && cloneElement2(iconRight, {
          title: "",
          className: iconRightClass
        })
      ] })
    }
  );
});

// src/components/badge/Badge.tsx
import clsx5 from "clsx";
import { jsx as jsx6, jsxs as jsxs5 } from "react/jsx-runtime";
function Badge({
  size = "base",
  type = "notification",
  variant = "primary",
  description,
  children,
  className,
  ...props
}) {
  const isNotification = type === "notification";
  const isInformation = type === "information";
  const isPrimary = variant === "primary";
  const isNeutral = variant === "neutral";
  const isNeutralLight = variant === "neutral-light";
  const isError = variant === "error";
  const isSuccess = variant === "success";
  const isLinen = variant === "linen";
  const isJasmine = variant === "jasmine";
  const isLavender = variant === "lavender";
  return /* @__PURE__ */ jsxs5(
    "span",
    {
      className: clsx5(
        "inline-flex items-center justify-center",
        {
          "cap-xs": size === "base",
          "cap-xxs": size === "sm",
          "rounded px-2 py-1.5": isInformation,
          "rounded-full px-1": isNotification,
          "h-4.5 min-w-[1.125rem]": isNotification && size === "sm",
          "h-6 min-w-[1.5rem]": isNotification && size !== "sm",
          "bg-primary text-white": isPrimary,
          "bg-neutral text-white": isNeutral,
          "bg-neutral-30 text-neutral-70": isNeutralLight,
          "bg-error text-white": isError,
          "bg-success text-white": isSuccess,
          "bg-linen-60 text-neutral-70": isLinen,
          "bg-jasmine-60 text-neutral-70": isJasmine,
          "bg-lavender-60 text-neutral-70": isLavender
        },
        className
      ),
      ...props,
      children: [
        children,
        description && /* @__PURE__ */ jsx6("span", { className: "sr-only", children: description })
      ]
    }
  );
}

// src/components/breadcrumbs/Breadcrumbs.tsx
import { Children, cloneElement as cloneElement3 } from "react";
import { jsx as jsx7 } from "react/jsx-runtime";
function Breadcrumbs({
  children,
  className,
  ...props
}) {
  const arrayChildren = Children.toArray(children);
  return /* @__PURE__ */ jsx7("nav", { className, "aria-label": "breadcrumbs", children: /* @__PURE__ */ jsx7("ol", { className: "flex", ...props, children: Children.map(arrayChildren, (child, index) => {
    const c = child;
    return cloneElement3(c, {
      last: index === arrayChildren.length - 1
    });
  }) }) });
}

// src/components/breadcrumbs/BreadcrumbItem.tsx
import clsx6 from "clsx";
import ArrowLeft2 from "@fu/icons/ArrowLeft";
import { jsx as jsx8, jsxs as jsxs6 } from "react/jsx-runtime";
function BreadcrumbItem({
  last = false,
  children,
  className
}) {
  const cssClass = clsx6(
    "inline-flex items-center",
    !last && "after:content-['/'] after:mx-2 after:text-neutral-50 hidden sm:inline-block",
    last && "sm:after:content-['/'] sm:after:mx-2 sm:after:text-neutral-50 inline-block",
    className
  );
  return /* @__PURE__ */ jsxs6("li", { className: cssClass, children: [
    last && /* @__PURE__ */ jsx8(ArrowLeft2, { className: clsx6("mr-1 sm:hidden"), "aria-hidden": true }),
    children
  ] });
}

// src/components/breadcrumbs/BreadcrumbText.tsx
import clsx7 from "clsx";
import { jsx as jsx9 } from "react/jsx-runtime";
function BreadcrumbText({ children, className }) {
  return /* @__PURE__ */ jsx9(
    "span",
    {
      className: clsx7(
        "meta-sm",
        "underline-offset-2",
        "decoration-primary-50",
        "decoration-2",
        "hover:underline",
        className
      ),
      children
    }
  );
}

// src/components/buttons/button/Button.tsx
import { forwardRef as forwardRef5, isValidElement as isValidElement4, cloneElement as cloneElement5 } from "react";
import clsx9 from "clsx";
import ArrowLeft3 from "@fu/icons/ArrowLeft";
import ArrowRight2 from "@fu/icons/ArrowRight";

// src/components/tooltip/Tooltip.tsx
import {
  useRef as useRef2,
  useState as useState2,
  useEffect as useEffect2,
  forwardRef as forwardRef3,
  useId as useId3,
  cloneElement as cloneElement4
} from "react";
import { ResizeObserver as ResizeObserver2 } from "@juggle/resize-observer";
import useMeasure2 from "react-use-measure";
import "what-input";

// src/utils/index.ts
function mergeRefs(...refs) {
  return (value) => {
    refs.flat().forEach((ref) => {
      if (typeof ref === "function") {
        ref(value);
      } else if (ref !== null) {
        ref.current = value;
      }
    });
  };
}

// src/components/tooltip/TooltipPortal.tsx
import { useRef, useEffect, isValidElement as isValidElement3 } from "react";
import { createPortal } from "react-dom";
function isBrowser() {
  return !!(typeof window !== "undefined" && window.document && window.document.createElement);
}
function TooltipPortal({
  children
}) {
  const portal = useRef(
    isBrowser() ? document.createElement("div") : null
  );
  useEffect(() => {
    if (isBrowser() && !portal.current) {
      portal.current = document.createElement("div");
    }
    portal.current?.classList.add("fuui-tooltip");
  }, []);
  useEffect(() => {
    const target = document ? document.body : null;
    if (!isBrowser() || !(target instanceof HTMLElement) || !(portal.current instanceof HTMLElement)) {
      return;
    }
    const node = portal.current;
    target.appendChild(node);
    return () => {
      target.removeChild(node);
    };
  }, [portal]);
  if (!portal.current || !isValidElement3(children)) {
    return null;
  }
  return createPortal(children, portal.current);
}

// src/components/tooltip/TooltipCore.tsx
import { useState, useMemo, useId as useId2 } from "react";
import { useSpring, animated } from "@react-spring/web";
import { ResizeObserver } from "@juggle/resize-observer";
import useMeasure from "react-use-measure";
import clsx8 from "clsx";

// src/components/tooltip/utils.ts
function getDocumentBodyDimensions() {
  return document?.body && typeof document?.body.getBoundingClientRect === "function" ? document.body.getBoundingClientRect() : {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    width: 0,
    height: 0,
    x: 0,
    y: 0
  };
}
function resolveTooltipPosition(pos, possibilities, preference = ["top", "bottom", "right", "left"]) {
  if (!possibilities[pos]) {
    for (const preferredPosition of preference) {
      if (possibilities[preferredPosition]) {
        return preferredPosition;
      }
    }
  }
  return pos;
}
function calcContainerPosition({
  position,
  targetBounds,
  tooltipBounds,
  offset
}) {
  const centerX = targetBounds.width / 2 - tooltipBounds.width / 2;
  const centerY = targetBounds.height / 2 - tooltipBounds.height / 2;
  switch (position) {
    case "top":
      return {
        top: targetBounds.y - tooltipBounds.height - offset,
        left: targetBounds.x + centerX
      };
    case "bottom":
      return {
        top: targetBounds.y + targetBounds.height + offset,
        left: targetBounds.x + centerX
      };
    case "left":
      return {
        top: targetBounds.y + centerY,
        left: targetBounds.x - tooltipBounds.width - offset
      };
    case "right":
      return {
        top: targetBounds.y + centerY,
        left: targetBounds.x + targetBounds.width + offset
      };
    default:
      break;
  }
  return {
    top: 0,
    left: 0
  };
}

// src/components/tooltip/TooltipCore.tsx
import { jsx as jsx10, jsxs as jsxs7 } from "react/jsx-runtime";
function TooltipCore({
  id: initialId,
  position: initialPosition = "top",
  target,
  targetBounds,
  active = false,
  className,
  children
}) {
  const uniqueId = useId2();
  const id = initialId || uniqueId;
  const [ref, tooltipBounds] = useMeasure({ polyfill: ResizeObserver });
  const [hasInteraction, setHasInteraction] = useState(false);
  const arrowY = "border-x-[7px] border-x-transparent";
  const arrowX = "border-y-[7px] border-y-transparent";
  const arrowTop = clsx8(arrowY, "border-b-[7px] border-b-neutral-70");
  const arrowRight = clsx8(arrowX, "border-l-[7px] border-l-neutral-70");
  const arrowBottom = clsx8(arrowY, "border-t-[7px] border-t-neutral-70");
  const arrowLeft = clsx8(arrowX, "border-r-[7px] border-r-neutral-70");
  const targetOffset = 16;
  const edgeOffset = 16;
  const {
    position,
    offsetDirection,
    containerStyles,
    arrowStyles
  } = useMemo(() => {
    if (!target?.current || !targetBounds) {
      return {
        position: initialPosition,
        offsetDirection: { x: 0, y: 0 },
        containerStyles: {},
        arrowStyles: {}
      };
    }
    const { width, height } = getDocumentBodyDimensions();
    const totalWidth = tooltipBounds.width + targetOffset + edgeOffset;
    const totalHeight = tooltipBounds.height + targetOffset + edgeOffset;
    const position2 = resolveTooltipPosition(initialPosition, {
      top: targetBounds.y - totalHeight > 0,
      right: targetBounds.x + targetBounds.width + totalWidth < width,
      bottom: targetBounds.y + targetBounds.height + totalHeight < height,
      left: targetBounds.x - totalWidth > 0
    });
    const offsetDirection2 = {
      x: position2 === "right" ? -1 : position2 === "left" ? 1 : 0,
      y: position2 === "top" ? -1 : position2 === "bottom" ? 1 : 0
    };
    const container = calcContainerPosition({
      position: position2,
      targetBounds,
      tooltipBounds,
      offset: targetOffset
    });
    const modifiers = {
      top: Math.max((container.top - edgeOffset) * -1, 0),
      left: Math.max((container.left - edgeOffset) * -1, 0),
      right: Math.min(
        width - (container.left + tooltipBounds.width + edgeOffset),
        0
      ),
      bottom: Math.min(
        height - (container.top + tooltipBounds.height + edgeOffset),
        0
      )
    };
    const containerStyles2 = {
      position: "absolute",
      top: container.top + modifiers.top,
      left: container.left + modifiers.left,
      pointerEvents: "none"
    };
    const arrowStyles2 = {
      left: ["top", "bottom"].includes(position2) ? `calc(50% - 7px - ${modifiers.left}px)` : "",
      top: ["left", "right"].includes(position2) ? `calc(50% - 7px - ${modifiers.top}px)` : ""
    };
    return {
      position: position2,
      offsetDirection: offsetDirection2,
      containerStyles: containerStyles2,
      arrowStyles: arrowStyles2
    };
  }, [target, targetBounds, tooltipBounds, initialPosition]);
  const x = active || hasInteraction ? 0 : offsetDirection.x * targetOffset;
  const y = active || hasInteraction ? 0 : offsetDirection.y * -targetOffset;
  const opacity = active || hasInteraction ? 1 : 0;
  const anim = useSpring({
    to: { x, y, opacity }
  });
  return /* @__PURE__ */ jsxs7(
    animated.span,
    {
      id,
      role: "tooltip",
      "aria-hidden": "true",
      style: { ...anim, ...containerStyles },
      ref,
      className: clsx8(
        "relative block max-w-[min(calc(100%-2rem),20rem)] rounded bg-neutral-70 p-3",
        className
      ),
      children: [
        /* @__PURE__ */ jsx10("span", { id, role: "tooltip", className: clsx8("body-xs text-white"), children }),
        position && /* @__PURE__ */ jsx10(
          "span",
          {
            style: arrowStyles,
            className: clsx8("absolute h-0 w-0", {
              ["top-[calc(100%-1px)]"]: position === "top",
              ["bottom-[calc(100%-1px)]"]: position === "bottom",
              ["left-[calc(100%-1px)]"]: position === "left",
              ["right-[calc(100%-1px)]"]: position === "right",
              ["left-[calc(50%-7px)]"]: ["top", "bottom"].includes(position),
              ["top-[calc(50%-7px)]"]: ["left", "right"].includes(position),
              [arrowBottom]: position === "top",
              [arrowTop]: position === "bottom",
              [arrowRight]: position === "left",
              [arrowLeft]: position === "right"
            })
          }
        )
      ]
    }
  );
}

// src/components/tooltip/Tooltip.tsx
import { Fragment, jsx as jsx11, jsxs as jsxs8 } from "react/jsx-runtime";
function isTouch(event) {
  return /touch/i.test(event.type);
}
var TooltipTarget = forwardRef3(function TooltipTarget2({ children, ...props }, ref) {
  return /* @__PURE__ */ jsx11("span", { ref, ...props, children });
});
function Tooltip({
  id: initialId,
  children,
  content,
  active: initialActive = false,
  showDelay = 0,
  hideDelay = 400,
  ...props
}) {
  const uniqueId = useId3();
  const id = initialId || uniqueId;
  const target = useRef2();
  const timer = useRef2();
  const [ref, bounds] = useMeasure2({ polyfill: ResizeObserver2 });
  const [active, setActive] = useState2(initialActive);
  const [isNotSemanticElement, setIsNotSemanticElement] = useState2(false);
  function handleSemanticElement() {
    try {
      if (target.current) {
        const role = target.current.getAttribute("role");
        if (/div|p|span/i.test(target.current?.tagName) && (!role || role === "text")) {
          setIsNotSemanticElement(true);
        }
      }
    } catch (err) {
      console.log(err);
    }
  }
  function handleFocus(event) {
    try {
      if (document.documentElement.getAttribute("data-whatintent") === "keyboard") {
        return activate(event);
      }
    } catch (err) {
      console.log(err);
    }
  }
  function activate(event) {
    try {
      const touch = isTouch(event);
      if (touch) {
        const el = event.currentTarget;
        if (el instanceof HTMLElement) {
          el.style.userSelect = "none";
        }
      }
    } catch (err) {
      console.log(err);
    }
    if (timer.current) {
      clearTimeout(timer.current);
    }
    timer.current = setTimeout(() => {
      setActive(true);
    }, showDelay ?? 1);
  }
  function deactivate(event) {
    try {
      const touch = isTouch(event);
      if (touch) {
        const el = event.currentTarget;
        if (el instanceof HTMLElement) {
          el.style.userSelect = "";
        }
      }
    } catch (err) {
      console.log(err);
    }
    if (timer.current) {
      clearTimeout(timer.current);
    }
    timer.current = setTimeout(() => {
      setActive(false);
    }, hideDelay ?? 1);
  }
  function bindEvents() {
    try {
      if (!target.current)
        return;
      target.current.addEventListener("mouseenter", activate);
      target.current.addEventListener("touchstart", activate);
      target.current.addEventListener("mouseleave", deactivate);
      target.current.addEventListener("touchend", deactivate);
      target.current.addEventListener("focus", handleFocus);
      target.current.addEventListener("blur", deactivate);
    } catch (err) {
      console.log(err);
    }
  }
  function unbindEvents() {
    try {
      if (!target.current)
        return;
      target.current.removeEventListener("mouseenter", activate);
      target.current.removeEventListener("touchstart", activate);
      target.current.removeEventListener("mouseleave", deactivate);
      target.current.removeEventListener("touchend", deactivate);
      target.current.removeEventListener("focus", handleFocus);
      target.current.removeEventListener("blur", deactivate);
    } catch (err) {
      console.log(err);
    }
  }
  useEffect2(() => {
    handleSemanticElement();
    bindEvents();
    return () => {
      unbindEvents();
    };
  }, []);
  const params = isNotSemanticElement ? { tabIndex: 0 } : {};
  return /* @__PURE__ */ jsxs8(Fragment, { children: [
    cloneElement4(children, {
      ref: mergeRefs(ref, target),
      "aria-describedby": id,
      ...params
    }),
    /* @__PURE__ */ jsx11(TooltipPortal, { children: /* @__PURE__ */ jsx11(
      TooltipCore,
      {
        target,
        targetBounds: bounds,
        id,
        active,
        ...props,
        children: content
      }
    ) })
  ] });
}

// src/components/buttons/ButtonWrapper.tsx
import { forwardRef as forwardRef4 } from "react";
var ButtonWrapper = forwardRef4(function ButtonWrapper2({ children, ...props }, ref) {
  if (typeof props.href !== "undefined") {
    return /* @__PURE__ */ React.createElement(
      "a",
      {
        ...props,
        ref
      },
      children
    );
  }
  return /* @__PURE__ */ React.createElement(
    "button",
    {
      ...props,
      ref
    },
    children
  );
});

// src/components/buttons/button/Button.tsx
import { jsx as jsx12, jsxs as jsxs9 } from "react/jsx-runtime";
var Button = forwardRef5(function Button2({
  variant = "default",
  size = "base",
  disabled = false,
  disabledReason,
  iconLeft,
  iconRight,
  stretch = false,
  onClick,
  children,
  className,
  ...props
}, ref) {
  function handleClick(event) {
    if (disabled) {
      console.warn("Button is disabled, click is ignored");
      return;
    }
    if (typeof onClick === "function") {
      onClick(event);
    }
  }
  const iconLeftClass = clsx9("inline-block", {
    "mr-1.5": children || iconRight
  });
  const iconRightClass = clsx9("inline-block", {
    "ml-1.5": children || iconRight
  });
  const InnerButton = /* @__PURE__ */ jsxs9(
    ButtonWrapper,
    {
      className: clsx9(
        "inline-flex items-center justify-center",
        "w-fit",
        "whitespace-nowrap",
        "rounded-full",
        {
          "cap-sm px-4 py-2": size === "xs",
          "pl-3": size === "xs" && iconLeft,
          "pr-3": size === "xs" && iconRight,
          "cap-sm px-5.5 py-2.5": size === "sm",
          "pl-3.5": size === "sm" && iconLeft,
          "pr-3.5": size === "sm" && iconRight,
          "cap-base px-6 py-3": size === "base",
          "pl-5": size === "base" && iconLeft,
          "pr-5": size === "base" && iconRight,
          "cap-md py-4 px-9": size === "lg",
          "pl-7": size === "lg" && iconLeft,
          "pr-7 ": size === "lg" && iconRight,
          "justify-between": stretch,
          "cursor-not-allowed bg-neutral-30 text-neutral-50": disabled,
          "bg-primary-60 text-white antialiased hover:bg-primary-80": !disabled && variant === "primary",
          "bg-neutral-70 text-white antialiased hover:bg-neutral-60": !disabled && variant === "default",
          "bg-neutral-30 text-neutral-70 hover:bg-neutral-40": !disabled && variant === "muted",
          "border-2 border-neutral-70 bg-transparent text-neutral-70 hover:bg-neutral-10": !disabled && variant === "ghost"
        },
        className
      ),
      ...props,
      ref,
      onClick: handleClick,
      "aria-disabled": disabled,
      children: [
        iconLeft === "arrow-left" && /* @__PURE__ */ jsx12(ArrowLeft3, { className: iconLeftClass }),
        typeof iconLeft !== "string" && isValidElement4(iconLeft) && cloneElement5(iconLeft, {
          title: "",
          className: clsx9(iconLeftClass, iconLeft.props.className)
        }),
        children,
        "arrow-right" === iconRight && /* @__PURE__ */ jsx12(ArrowRight2, { className: iconRightClass }),
        typeof iconRight !== "string" && isValidElement4(iconRight) && cloneElement5(iconRight, {
          title: "",
          className: clsx9(iconRightClass, iconRight.props.className)
        })
      ]
    }
  );
  if (disabled && disabledReason) {
    return /* @__PURE__ */ jsx12(Tooltip, { position: "top", content: disabledReason, children: InnerButton });
  }
  return InnerButton;
});

// src/components/buttons/icon-button/IconButton.tsx
import { forwardRef as forwardRef6 } from "react";
import clsx10 from "clsx";
import { jsx as jsx13 } from "react/jsx-runtime";
var IconButton = forwardRef6(function IconButton2({
  variant = "default",
  size = "base",
  disabled = false,
  disabledReason,
  container = true,
  rounded = false,
  onClick,
  className,
  children,
  ...props
}, ref) {
  function handleClick() {
    if (disabled) {
      console.warn("Button is disabled, click is ignored");
      return;
    }
    if (typeof onClick === "function") {
      onClick();
    }
  }
  return /* @__PURE__ */ jsx13(
    ButtonWrapper,
    {
      className: clsx10(
        "inline-flex items-center justify-center",
        rounded && "rounded-full",
        "disabled:cursor-not-allowed disabled:border-2 disabled:border-neutral-30 disabled:bg-neutral-30 disabled:text-neutral-50",
        {
          ["h-3 w-3 text-[0.5rem]"]: size === "xxs",
          ["h-4 w-4 text-[0.5rem]"]: size === "xs",
          ["h-5 w-5 text-xxs"]: size === "sm",
          ["h-6 w-6 text-xs"]: size === "base",
          ["h-8 w-8 text-base"]: size === "md",
          ["h-10 w-10 text-md"]: size === "lg",
          ["h-14 w-14 text-[1.75rem]"]: size === "xl",
          ["h-16 w-16 text-xl"]: size === "2xl",
          ["h-24 w-24 text-3xl"]: size === "3xl",
          "border-2 border-primary-60 bg-primary-60 text-white hover:border-primary-80 hover:bg-primary-80": container && variant === "primary",
          "border-2 border-neutral-70 bg-neutral-70 text-white hover:border-neutral-60 hover:bg-neutral-60": container && variant === "default",
          "border-2 border-white bg-white text-neutral-70 hover:border-neutral-70 hover:bg-neutral-70 hover:text-white": container && variant === "inverted",
          "border-2 border-neutral-30 bg-neutral-30 text-neutral-70 hover:border-neutral-40 hover:bg-neutral-40": container && variant === "muted",
          "border-2 border-neutral-70 bg-neutral-0 text-neutral-70 hover:bg-neutral-10": container && variant === "ghost"
        },
        className
      ),
      ...props,
      ref,
      onClick: handleClick,
      title: disabled && disabledReason ? disabledReason : void 0,
      "aria-disabled": disabled,
      children
    }
  );
});

// src/components/course-card/CourseCard.tsx
import clsx11 from "clsx";
import { jsx as jsx14, jsxs as jsxs10 } from "react/jsx-runtime";
function CourseCard({
  label,
  children,
  className,
  href
}) {
  const cssClass = clsx11(
    "inline-flex flex-col p-4 bg-neutral-0 text-neutral justify-between",
    className
  );
  return /* @__PURE__ */ jsxs10("div", { className: cssClass, children: [
    /* @__PURE__ */ jsx14("div", { children }),
    /* @__PURE__ */ jsx14(
      Button,
      {
        variant: "primary",
        size: "base",
        href,
        iconRight: "arrow-right",
        stretch: true,
        className: "mt-6",
        children: label
      }
    )
  ] });
}

// src/components/drawer/Drawer.tsx
import { cloneElement as cloneElement6 } from "react";
import { Dialog } from "@headlessui/react";
import { animated as animated2, useTransition } from "@react-spring/web";
import { jsx as jsx15, jsxs as jsxs11 } from "react/jsx-runtime";
function Drawer({
  children,
  side = "right",
  isOpen = false,
  onClose
}) {
  const transitions = useTransition(isOpen, {
    from: { x: 0 },
    enter: { x: 1 },
    leave: { x: 0 },
    reverse: !isOpen
  });
  return transitions(
    ({ x }, item) => item && /* @__PURE__ */ jsxs11(
      Dialog,
      {
        open: true,
        onClose,
        className: "relative z-20",
        unmount: false,
        children: [
          /* @__PURE__ */ jsx15(
            animated2.div,
            {
              style: { opacity: x },
              className: "fixed inset-0 backdrop-blur-sm",
              "aria-hidden": true,
              children: /* @__PURE__ */ jsx15("div", { className: "absolute h-screen w-screen bg-black bg-opacity-20" })
            }
          ),
          /* @__PURE__ */ jsx15(
            animated2.div,
            {
              style: {
                transform: x.to(
                  (v) => `translate3d(${(side === "right" ? 1 + v * -1 : v) * 100}%, 0, 0)`
                )
              },
              className: "fixed inset-0 overflow-y-auto",
              children: /* @__PURE__ */ jsx15("div", { className: "flex min-h-full items-center justify-center", children: /* @__PURE__ */ jsx15(Dialog.Panel, { className: "bg-primary-60", children: cloneElement6(children, {
                onClose
              }) }) })
            }
          )
        ]
      }
    )
  );
}

// src/components/flag/Flag.tsx
import clsx12 from "clsx";
import { jsx as jsx16 } from "react/jsx-runtime";
function Flag({ code, className, ...props }) {
  return /* @__PURE__ */ jsx16("span", { className: clsx12("overflow-hidden rounded", className), ...props, children: /* @__PURE__ */ jsx16("span", { className: clsx12("flag-icon", `flag-icon-${code}`) }) });
}

// src/components/form/CheckboxWrapper.tsx
import clsx13 from "clsx";
import CircleHelp from "@fu/icons/CircleHelp";
import { Fragment as Fragment2, jsx as jsx17, jsxs as jsxs12 } from "react/jsx-runtime";
function CheckboxWrapper({
  id,
  label,
  subLabel,
  tooltip,
  error,
  align = "left",
  className,
  children
}) {
  return /* @__PURE__ */ jsxs12(Fragment2, { children: [
    /* @__PURE__ */ jsxs12(
      "div",
      {
        className: clsx13(
          "flex justify-end",
          {
            "items-center": !subLabel,
            "flex-row-reverse": align === "left"
          },
          className
        ),
        children: [
          /* @__PURE__ */ jsxs12("div", { className: clsx13("flex"), children: [
            label && /* @__PURE__ */ jsx17("label", { htmlFor: id, className: clsx13("cap-base", "text-neutral-60"), children: /* @__PURE__ */ jsxs12("span", { children: [
              label,
              subLabel && /* @__PURE__ */ jsx17("span", { className: "cap-xs block", children: subLabel })
            ] }) }),
            tooltip && /* @__PURE__ */ jsx17(Tooltip, { content: tooltip, children: /* @__PURE__ */ jsx17(TooltipTarget, { className: clsx13("ml-2"), children: /* @__PURE__ */ jsx17(CircleHelp, {}) }) })
          ] }),
          children
        ]
      }
    ),
    error && /* @__PURE__ */ jsx17(Alert, { type: "error", role: "alert", message: error, inline: true })
  ] });
}

// src/components/form/checkbox/Checkbox.tsx
import { forwardRef as forwardRef7, useRef as useRef3, useId as useId4 } from "react";
import clsx14 from "clsx";
import Check from "@fu/icons/Check";
import { jsx as jsx18, jsxs as jsxs13 } from "react/jsx-runtime";
var Checkbox = forwardRef7(function Checkbox2({
  id: initialId,
  name,
  label,
  subLabel,
  align = "left",
  tooltip,
  disabled,
  error,
  className,
  ...props
}, ref) {
  const inputRef = useRef3(null);
  const uniqueId = useId4();
  const id = initialId || uniqueId;
  return /* @__PURE__ */ jsx18(
    CheckboxWrapper,
    {
      id,
      label,
      subLabel,
      align,
      tooltip,
      error,
      className,
      children: /* @__PURE__ */ jsxs13(
        "span",
        {
          className: clsx14(
            "relative",
            "flex flex-none items-center justify-center",
            "h-6 w-6",
            {
              "mr-3": align === "left",
              "ml-3": align === "right"
            }
          ),
          children: [
            /* @__PURE__ */ jsx18(
              "input",
              {
                ref: mergeRefs(ref, inputRef),
                id,
                name,
                type: "checkbox",
                "aria-invalid": !!error,
                disabled,
                className: clsx14(
                  "peer absolute top-0 left-0",
                  "h-full w-full",
                  "appearance-none",
                  "rounded border-2 border-neutral-70",
                  "bg-neutral-0",
                  "outline-none",
                  "disabled:border-neutral-50 disabled:checked:bg-neutral-50",
                  {
                    "checked:bg-neutral-70": !disabled,
                    "focus:state-focus-light": !disabled,
                    "active:state-active": !disabled,
                    "hover:border-neutral-60 hover:checked:bg-neutral-60": !disabled && !error,
                    "state-error-light border-neutral-70": !!error
                  }
                ),
                ...props
              }
            ),
            /* @__PURE__ */ jsx18(
              Check,
              {
                className: clsx14(
                  "relative",
                  "text-white",
                  "pointer-events-none",
                  "invisible peer-checked:visible"
                )
              }
            )
          ]
        }
      )
    }
  );
});

// src/components/form/input/Input.tsx
import { forwardRef as forwardRef8, useState as useState3, useRef as useRef4, useId as useId5, isValidElement as isValidElement5 } from "react";
import clsx15 from "clsx";
import Eye from "@fu/icons/Eye";
import EyeOff from "@fu/icons/EyeOff";
import Close from "@fu/icons/Close";
import { Fragment as Fragment3, jsx as jsx19, jsxs as jsxs14 } from "react/jsx-runtime";
var Input = forwardRef8(function Input2({
  id: initialId,
  name,
  label,
  type: initialType = "text",
  icon,
  helperText,
  error,
  resetField,
  className,
  disabled,
  ...props
}, ref) {
  const inputRef = useRef4(null);
  const uniqueId = useId5();
  const id = initialId || uniqueId;
  const [type, setType] = useState3(initialType);
  const [hasValue, setHasValue] = useState3(!!props.defaultValue);
  function showPasswordHandler(event) {
    event.preventDefault();
    setType(type === "password" ? "text" : "password");
  }
  function resetHandler(event) {
    event.preventDefault();
    if (typeof resetField === "function") {
      resetField(name, {
        keepDirty: true,
        keepTouched: true
      });
    } else if (inputRef.current instanceof HTMLInputElement) {
      inputRef.current.value === "";
    }
  }
  function changeHandler(event) {
    if (!hasValue && event.currentTarget.value) {
      setHasValue(true);
    }
    if (hasValue && !event.currentTarget.value) {
      setHasValue(false);
    }
    if (typeof props.onChange === "function") {
      props.onChange(event);
    }
  }
  const centeredContent = clsx15("flex justify-center items-center");
  return /* @__PURE__ */ jsx19(Fragment3, { children: /* @__PURE__ */ jsxs14("div", { className: clsx15("text-neutral-60", className), children: [
    label && /* @__PURE__ */ jsx19("label", { htmlFor: id, className: clsx15("cap-md"), children: label }),
    /* @__PURE__ */ jsxs14("div", { className: clsx15("relative", "my-2"), children: [
      /* @__PURE__ */ jsx19(
        "input",
        {
          ref: mergeRefs(ref, inputRef),
          id,
          name,
          type,
          "aria-invalid": !!error,
          disabled,
          className: clsx15(
            !icon ? "pl-4" : "pl-12",
            initialType !== "password" ? "pr-10" : "pr-18",
            "h-14 w-full",
            "body-base",
            "rounded border-1 border-neutral-30",
            "bg-neutral-30",
            "outline-none",
            "disabled:opacity-50",
            {
              "focus:state-focus-light": !disabled,
              "active:state-active": !disabled,
              "hover:border-neutral-60": !disabled && !error,
              "state-error-light": !!error
            }
          ),
          ...props,
          onChange: changeHandler
        }
      ),
      isValidElement5(icon) && /* @__PURE__ */ jsx19(
        "span",
        {
          className: clsx15(
            "absolute top-0 left-0",
            "h-full w-12",
            centeredContent
          ),
          children: icon
        }
      ),
      hasValue && /* @__PURE__ */ jsx19(
        "button",
        {
          onClick: resetHandler,
          className: clsx15(
            "absolute top-0",
            initialType !== "password" ? "right-0" : "right-12",
            initialType !== "password" ? "w-12" : "w-4",
            "h-full",
            centeredContent
          ),
          children: /* @__PURE__ */ jsx19(Close, {})
        }
      ),
      initialType === "password" && /* @__PURE__ */ jsx19(
        "button",
        {
          onClick: showPasswordHandler,
          className: clsx15(
            "absolute top-0 right-0",
            "h-full w-12",
            centeredContent
          ),
          children: type === "text" ? /* @__PURE__ */ jsx19(EyeOff, {}) : /* @__PURE__ */ jsx19(Eye, {})
        }
      )
    ] }),
    helperText && /* @__PURE__ */ jsx19("p", { className: "cap-xs", children: helperText }),
    error && /* @__PURE__ */ jsx19(Alert, { type: "error", role: "alert", message: error, inline: true })
  ] }) });
});

// src/components/form/radio/Radio.tsx
import { forwardRef as forwardRef9, useRef as useRef5, useId as useId6 } from "react";
import clsx16 from "clsx";
import { jsx as jsx20, jsxs as jsxs15 } from "react/jsx-runtime";
var Radio = forwardRef9(function Radio2({
  id: initialId,
  name,
  label,
  subLabel,
  align = "left",
  tooltip,
  disabled,
  error,
  className,
  ...props
}, ref) {
  const inputRef = useRef5(null);
  const uniqueId = useId6();
  const id = initialId || uniqueId;
  return /* @__PURE__ */ jsx20(
    CheckboxWrapper,
    {
      id,
      label,
      subLabel,
      align,
      tooltip,
      error,
      className,
      children: /* @__PURE__ */ jsxs15(
        "span",
        {
          className: clsx16(
            "relative",
            "flex h-6 w-6",
            "flex-none",
            "items-center justify-center",
            {
              "mr-3": align === "left",
              "ml-3": align === "right"
            }
          ),
          children: [
            /* @__PURE__ */ jsx20(
              "input",
              {
                ref: mergeRefs(ref, inputRef),
                id,
                name,
                type: "radio",
                disabled,
                className: clsx16(
                  "peer absolute top-0 left-0",
                  "h-full w-full",
                  "appearance-none",
                  "rounded-full border-2 border-neutral-70",
                  "bg-neutral-0",
                  "outline-none",
                  "disabled:border-neutral-50 disabled:checked:bg-neutral-50",
                  {
                    "checked:bg-neutral-70": !disabled,
                    "focus:state-focus-light": !disabled,
                    "active:state-active": !disabled,
                    "hover:border-neutral-60 hover:checked:bg-neutral-60": !disabled && !error,
                    "state-error-light border-neutral-70": !!error
                  }
                ),
                ...props
              }
            ),
            /* @__PURE__ */ jsx20(
              "span",
              {
                className: clsx16(
                  "relative",
                  "h-3 w-3",
                  "bg-neutral-0",
                  "rounded-full",
                  "pointer-events-none",
                  "invisible peer-checked:visible"
                )
              }
            )
          ]
        }
      )
    }
  );
});

// src/components/form/toggle/Toggle.tsx
import { forwardRef as forwardRef10, useRef as useRef6, useId as useId7 } from "react";
import clsx17 from "clsx";
import { jsx as jsx21, jsxs as jsxs16 } from "react/jsx-runtime";
var Toggle = forwardRef10(function Toggle2({
  id: initialId,
  name,
  label,
  subLabel,
  align = "left",
  tooltip,
  checked,
  disabled,
  error,
  className,
  ...props
}, ref) {
  const inputRef = useRef6(null);
  const uniqueId = useId7();
  const id = initialId || uniqueId;
  return /* @__PURE__ */ jsx21(
    CheckboxWrapper,
    {
      id,
      label,
      subLabel,
      align,
      tooltip,
      error,
      className,
      children: /* @__PURE__ */ jsxs16(
        "span",
        {
          className: clsx17(
            "relative flex h-6 w-11 flex-none items-center justify-center",
            {
              "mr-3": align === "left",
              "ml-3": align === "right"
            }
          ),
          children: [
            /* @__PURE__ */ jsx21(
              "input",
              {
                ref: mergeRefs(ref, inputRef),
                id,
                name,
                type: "checkbox",
                "aria-invalid": !!error,
                disabled,
                checked,
                className: clsx17(
                  "peer",
                  "absolute top-0 left-0",
                  "h-full w-full",
                  "appearance-none",
                  "rounded-full border-2 border-neutral-50 bg-neutral-50",
                  "outline-none",
                  "checked:border-neutral-70 checked:bg-neutral-70",
                  "disabled:border-neutral-50 disabled:bg-neutral-50",
                  {
                    "focus:state-focus-light": !disabled,
                    "active:state-active": !disabled,
                    "hover:border-neutral-60 hover:bg-neutral-60 hover:checked:bg-neutral-60": !disabled && !error,
                    "state-error-light border-neutral-70": !!error
                  }
                ),
                ...props
              }
            ),
            /* @__PURE__ */ jsx21(
              "span",
              {
                className: clsx17(
                  "relative",
                  "h-5 w-5",
                  "bg-neutral-0",
                  "rounded-full",
                  "pointer-events-none",
                  "-translate-x-[10px] peer-checked:translate-x-[10px]",
                  "transition-transform duration-150 ease-in-out"
                )
              }
            )
          ]
        }
      )
    }
  );
});

// src/components/form/select/Select.tsx
import { forwardRef as forwardRef11, useState as useState4, useRef as useRef7, useId as useId8, isValidElement as isValidElement6 } from "react";
import clsx18 from "clsx";
import { Close as Close2, ChevronDown } from "@fu/icons";
import { jsx as jsx22, jsxs as jsxs17 } from "react/jsx-runtime";
var Select = forwardRef11(function Select2({
  name,
  label,
  icon,
  helperText,
  error,
  resetField,
  className,
  disabled,
  children,
  ...props
}, ref) {
  const id = useId8();
  const inputRef = useRef7(null);
  const [hasValue, setHasValue] = useState4(
    !!(props.value || props.defaultValue)
  );
  function resetHandler(event) {
    event.preventDefault();
    if (typeof resetField === "function") {
      resetField(name, {
        keepDirty: true,
        keepTouched: true
      });
    } else if (inputRef.current instanceof HTMLSelectElement) {
      inputRef.current.value === "";
      setHasValue(false);
    }
  }
  function changeHandler(event) {
    if (!hasValue && event.currentTarget.value) {
      setHasValue(true);
    }
    if (hasValue && !event.currentTarget.value) {
      setHasValue(false);
    }
    if (typeof props.onChange === "function") {
      props.onChange(event);
    }
  }
  const centeredContent = clsx18("flex justify-center items-center");
  return /* @__PURE__ */ jsxs17("div", { className: clsx18("text-neutral-80", className), children: [
    label && /* @__PURE__ */ jsx22("label", { htmlFor: props.id || id, className: clsx18("cap-md"), children: label }),
    /* @__PURE__ */ jsxs17("div", { className: clsx18("relative", "my-2"), children: [
      /* @__PURE__ */ jsxs17(
        "select",
        {
          ref: mergeRefs(ref, inputRef),
          id,
          name,
          "aria-invalid": !!error,
          disabled,
          className: clsx18(
            "appearance-none",
            !icon ? "pl-4" : "pl-12",
            "h-14 w-full",
            "body-base",
            "cursor-pointer",
            "rounded border-1 border-neutral-30",
            "outline-none",
            "bg-neutral-30",
            "disabled:opacity-50",
            "elevation-neutral-sm",
            {
              "focus:state-focus-light": !disabled,
              "active:state-active": !disabled,
              "hover:border-neutral-60": !disabled && !error,
              "state-error-light": !!error
            }
          ),
          ...props,
          onChange: changeHandler,
          children: [
            props.placeholder && /* @__PURE__ */ jsx22("option", { hidden: true, value: "", className: " bg-black text-neutral-50", children: props.placeholder }),
            children
          ]
        }
      ),
      isValidElement6(icon) && /* @__PURE__ */ jsx22(
        "span",
        {
          className: clsx18(
            "absolute",
            "top-0",
            "left-0",
            "w-12",
            "h-full",
            centeredContent
          ),
          children: icon
        }
      ),
      hasValue && typeof resetHandler === "function" && /* @__PURE__ */ jsx22(
        "button",
        {
          onClick: (event) => {
            setHasValue(false);
            resetHandler(event);
          },
          className: clsx18(
            "absolute top-0 right-10",
            "h-full w-12",
            centeredContent
          ),
          children: /* @__PURE__ */ jsx22(Close2, {})
        }
      ),
      /* @__PURE__ */ jsx22(
        "span",
        {
          className: clsx18(
            "absolute",
            "top-0",
            "right-3",
            "w-9",
            "h-full",
            "pointer-events-none",
            centeredContent
          ),
          children: /* @__PURE__ */ jsx22(ChevronDown, { className: clsx18("w-5", "h-5") })
        }
      )
    ] }),
    helperText && /* @__PURE__ */ jsx22("p", { className: clsx18("cap-xs"), children: helperText }),
    error && /* @__PURE__ */ jsx22(Alert, { type: "error", role: "alert", message: error, inline: true })
  ] });
});

// src/components/heading/Heading.tsx
import clsx19 from "clsx";
import { jsx as jsx23 } from "react/jsx-runtime";
function Heading({
  size = "base",
  level = "h1",
  children,
  className,
  ...props
}) {
  const cssClass = clsx19(
    "font-semibold",
    "font-matter",
    "leading-snug",
    {
      ["hd-7xl"]: size === "7xl",
      ["hd-6xl"]: size === "6xl",
      ["hd-5xl"]: size === "5xl",
      ["hd-4xl"]: size === "4xl",
      ["hd-3xl"]: size === "3xl",
      ["hd-2xl"]: size === "2xl",
      ["hd-xl"]: size === "xl",
      ["hd-lg"]: size === "lg",
      ["hd-md"]: size === "md",
      ["hd-base"]: size === "base",
      ["hd-sm"]: size === "sm",
      ["hd-xs"]: size === "xs",
      ["hd-xxs"]: size === "xxs"
    },
    className
  );
  const Component = level;
  return /* @__PURE__ */ jsx23(Component, { className: cssClass, ...props, children });
}
function H1({ size = "7xl", ...props }) {
  return /* @__PURE__ */ jsx23(Heading, { level: "h1", size, ...props });
}
function H2({ size = "5xl", ...props }) {
  return /* @__PURE__ */ jsx23(Heading, { level: "h2", size, ...props });
}
function H3({ size = "3xl", ...props }) {
  return /* @__PURE__ */ jsx23(Heading, { level: "h3", size, ...props });
}
function H4({ size = "xl", ...props }) {
  return /* @__PURE__ */ jsx23(Heading, { level: "h4", size, ...props });
}
function H5({ size = "lg", ...props }) {
  return /* @__PURE__ */ jsx23(Heading, { level: "h5", size, ...props });
}
function H6({ size = "base", ...props }) {
  return /* @__PURE__ */ jsx23(Heading, { level: "h6", size, ...props });
}

// src/components/list/List.tsx
import { createElement } from "react";
import clsx20 from "clsx";

// stylePlugin:/vercel/path0/packages/ui/src/components/list/List.module.css
var List_module_default = {
  "unordered": "unordered__nDCZ3",
  "ordered": "ordered__panI3"
};

// src/components/list/List.tsx
function List({
  type = "unordered",
  children,
  className,
  ...props
}) {
  const common = clsx20("font-inter", "list-inside");
  const tag = type === "ordered" ? "ol" : "ul";
  return createElement(
    tag,
    {
      className: clsx20(
        "list-none",
        {
          [List_module_default.ordered]: type === "ordered",
          [List_module_default.unordered]: type === "unordered"
        },
        common,
        className
      ),
      ...props
    },
    children
  );
}

// src/components/list/ListItem.tsx
import { jsx as jsx24 } from "react/jsx-runtime";
function ListItem({ children, ...props }) {
  return /* @__PURE__ */ jsx24("li", { ...props, className: "my-2", children: /* @__PURE__ */ jsx24("span", { className: "body-sm text-neutral-70", children }) });
}

// src/components/loader/Loader.tsx
import clsx22 from "clsx";

// src/components/spinner/Spinner.tsx
import clsx21 from "clsx";
import { jsx as jsx25 } from "react/jsx-runtime";
function Spinner({ size = "lg" }) {
  return /* @__PURE__ */ jsx25("span", { className: "flex items-center justify-center", children: /* @__PURE__ */ jsx25(
    "span",
    {
      className: clsx21(
        `rounded-full border-primary-50 border-t-neutral-40`,
        "animate-spin ease-linear",
        {
          "h-4 w-4 border-2": size === "sm",
          "h-8 w-8 border-3": size === "md",
          "h-16 w-16 border-4": size === "lg"
        }
      )
    }
  ) });
}

// src/components/loader/Loader.tsx
import { jsx as jsx26, jsxs as jsxs18 } from "react/jsx-runtime";
function Loader({ fullscreen, cover, backdrop }) {
  return /* @__PURE__ */ jsxs18(
    "div",
    {
      className: clsx22("z-50 flex items-center justify-center", {
        "fixed top-0 left-0": fullscreen || cover,
        "h-screen w-screen": fullscreen,
        "h-full w-full": cover,
        "backdrop-blur-sm": backdrop
      }),
      children: [
        /* @__PURE__ */ jsx26(
          "div",
          {
            className: clsx22("flex items-center justify-center", {
              "fixed top-0 left-0": fullscreen || cover,
              "h-screen w-screen": fullscreen,
              "h-full w-full": cover,
              "bg-primary-lighten-60 opacity-20": backdrop
            })
          }
        ),
        /* @__PURE__ */ jsx26(Spinner, {})
      ]
    }
  );
}

// src/components/logo/Logo.tsx
import { jsx as jsx27, jsxs as jsxs19 } from "react/jsx-runtime";
function Logo({
  text = "none",
  white = false,
  ...props
}) {
  if (text === "vertical") {
    return /* @__PURE__ */ jsxs19(
      "svg",
      {
        viewBox: "0 0 231 150",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
        ...props,
        children: [
          /* @__PURE__ */ jsx27(
            "path",
            {
              d: "M139.883 14.5556C142.07 14.7398 142.253 14.9241 142.253 14.9241L139.518 9.76514H116.551H93.5832L91.0312 14.9241C91.0312 14.9241 91.2135 14.7398 93.4009 14.5556C111.811 12.8973 114.181 23.0309 114.546 31.6906C114.728 36.8495 114.728 41.0872 114.728 41.0872H116.551H118.374C118.374 41.0872 118.374 36.8495 118.556 31.6906C119.103 23.0309 121.472 12.8973 139.883 14.5556Z",
              fill: white ? "#fff" : "#FF3F2E"
            }
          ),
          /* @__PURE__ */ jsx27(
            "path",
            {
              d: "M129.311 33.9015C133.138 33.9015 136.237 30.7693 136.237 26.9001C136.237 23.0309 133.138 19.8987 129.311 19.8987C125.483 19.8987 122.384 23.0309 122.384 26.9001C122.384 30.7693 125.483 33.9015 129.311 33.9015Z",
              fill: white ? "#fff" : "#FF3F2E"
            }
          ),
          /* @__PURE__ */ jsx27(
            "path",
            {
              d: "M103.791 33.9015C107.619 33.9015 110.718 30.7693 110.718 26.9001C110.718 23.0309 107.619 19.8987 103.791 19.8987C99.9631 19.8987 96.8643 23.0309 96.8643 26.9001C96.8643 30.7693 99.9631 33.9015 103.791 33.9015Z",
              fill: white ? "#fff" : "#FF3F2E"
            }
          ),
          /* @__PURE__ */ jsx27(
            "path",
            {
              d: "M140.064 30.4009V67.8031H160.48L144.622 30.4009H140.064Z",
              fill: white ? "#fff" : "#FF3F2E"
            }
          ),
          /* @__PURE__ */ jsx27(
            "path",
            {
              d: "M93.0361 30.4009V67.8031H72.8027L88.6613 30.4009H93.0361Z",
              fill: white ? "#fff" : "#FF3F2E"
            }
          ),
          /* @__PURE__ */ jsx27(
            "path",
            {
              d: "M140.065 67.803C140.065 67.803 140.065 92.6763 116.551 92.6763C91.9425 92.6763 92.8539 67.803 92.8539 67.803C92.8539 67.803 100.51 75.5414 116.551 75.5414C133.138 75.5414 140.065 67.803 140.065 67.803Z",
              fill: white ? "#fff" : "#FF3F2E"
            }
          ),
          /* @__PURE__ */ jsx27(
            "path",
            {
              d: "M8.90039 140.637V120.705H21.2829V123.926H12.2225V129.564H20.3769V132.684H12.2225V140.738H8.90039V140.637Z",
              fill: white ? "#fff" : "#273659"
            }
          ),
          /* @__PURE__ */ jsx27(
            "path",
            {
              d: "M36.4847 133.792C36.4847 138.02 33.4645 141.04 29.337 141.04C25.2095 141.04 22.29 138.02 22.29 133.792C22.29 129.564 25.3102 126.543 29.337 126.543C33.4645 126.543 36.4847 129.564 36.4847 133.792ZM33.2632 133.792C33.2632 130.973 31.4511 129.463 29.4377 129.463C27.4243 129.463 25.6122 130.973 25.6122 133.792C25.6122 136.611 27.4243 138.121 29.4377 138.121C31.4511 138.121 33.2632 136.711 33.2632 133.792Z",
              fill: white ? "#fff" : "#273659"
            }
          ),
          /* @__PURE__ */ jsx27(
            "path",
            {
              d: "M39.1016 140.638V120.302H42.323V140.638H39.1016Z",
              fill: white ? "#fff" : "#273659"
            }
          ),
          /* @__PURE__ */ jsx27(
            "path",
            {
              d: "M52.3899 132.785L58.1282 140.738H54.1013L50.0745 135.101L48.3631 136.913V140.738H45.1416V120.302H48.3631V132.483L53.598 126.946H57.9268L52.3899 132.785Z",
              fill: white ? "#fff" : "#273659"
            }
          ),
          /* @__PURE__ */ jsx27(
            "path",
            {
              d: "M71.4175 136.711C70.7128 139.127 68.498 141.04 65.1759 141.04C61.4511 141.04 58.1289 138.322 58.1289 133.691C58.1289 129.362 61.3504 126.443 64.7732 126.443C69.0014 126.443 71.5182 129.262 71.5182 133.591C71.5182 134.094 71.4175 134.597 71.4175 134.597H61.3504C61.4511 136.711 63.0618 138.221 65.0752 138.221C67.0886 138.221 68.0954 137.215 68.498 135.805L71.4175 136.711ZM68.2967 132.282C68.196 130.671 67.1893 129.262 64.8739 129.262C62.8605 129.262 61.6524 130.872 61.5517 132.282H68.2967Z",
              fill: white ? "#fff" : "#273659"
            }
          ),
          /* @__PURE__ */ jsx27(
            "path",
            {
              d: "M78.9682 141.04C75.7467 141.04 73.834 138.624 73.834 135.604V126.946H77.0555V135C77.0555 136.711 77.8608 138.121 79.7736 138.121C81.6863 138.121 82.693 136.812 82.693 135.101V126.946H85.9145V138.121C85.9145 139.228 86.0152 140.134 86.0152 140.638H82.8944C82.7937 140.336 82.7937 139.631 82.7937 139.127C82.1897 140.336 80.5789 141.04 78.9682 141.04Z",
              fill: white ? "#fff" : "#273659"
            }
          ),
          /* @__PURE__ */ jsx27(
            "path",
            {
              d: "M92.5584 140.638H89.3369V126.946H92.5584V128.758C93.4644 127.148 95.0752 126.544 96.5852 126.544C99.9074 126.544 101.518 128.96 101.518 131.98V140.638H98.2967V132.483C98.2967 130.772 97.4913 129.463 95.4779 129.463C93.6658 129.463 92.6591 130.873 92.6591 132.685V140.638H92.5584Z",
              fill: white ? "#fff" : "#273659"
            }
          ),
          /* @__PURE__ */ jsx27(
            "path",
            {
              d: "M106.25 120C107.458 120 108.364 121.007 108.364 122.114C108.364 123.221 107.357 124.228 106.25 124.228C105.142 124.228 104.136 123.322 104.136 122.114C104.136 120.906 105.142 120 106.25 120ZM104.639 140.638V126.946H107.861V140.638H104.639Z",
              fill: white ? "#fff" : "#273659"
            }
          ),
          /* @__PURE__ */ jsx27(
            "path",
            {
              d: "M118.43 140.638H115.209L109.672 126.946H113.296L116.92 136.711L120.444 126.946H123.867L118.43 140.638Z",
              fill: white ? "#fff" : "#273659"
            }
          ),
          /* @__PURE__ */ jsx27(
            "path",
            {
              d: "M137.255 136.711C136.551 139.127 134.336 141.04 131.014 141.04C127.289 141.04 123.967 138.322 123.967 133.691C123.967 129.362 127.188 126.443 130.611 126.443C134.839 126.443 137.356 129.262 137.356 133.591C137.356 134.094 137.255 134.597 137.255 134.597H127.188C127.289 136.711 128.9 138.221 130.913 138.221C132.927 138.221 133.933 137.215 134.336 135.805L137.255 136.711ZM134.135 132.282C134.034 130.671 133.027 129.262 130.712 129.262C128.698 129.262 127.49 130.872 127.39 132.282H134.135Z",
              fill: white ? "#fff" : "#273659"
            }
          ),
          /* @__PURE__ */ jsx27(
            "path",
            {
              d: "M148.028 130.168C147.626 130.067 147.324 130.067 146.921 130.067C144.404 130.067 143.196 131.577 143.196 134.094V140.638H139.975V126.946H143.196V129.161C143.8 127.651 145.411 126.846 147.122 126.846C147.525 126.846 147.827 126.946 148.028 126.946V130.168Z",
              fill: white ? "#fff" : "#273659"
            }
          ),
          /* @__PURE__ */ jsx27(
            "path",
            {
              d: "M151.954 136.208C152.055 137.416 152.961 138.524 154.773 138.524C156.082 138.524 156.787 137.819 156.787 136.913C156.787 136.208 156.283 135.604 155.176 135.403L153.163 135C150.746 134.497 149.438 132.886 149.438 130.973C149.438 128.557 151.652 126.544 154.471 126.544C158.297 126.544 159.505 129.06 159.807 130.369L157.089 131.175C156.988 130.369 156.384 129.161 154.572 129.161C153.465 129.161 152.558 129.866 152.558 130.772C152.558 131.477 153.062 132.081 153.968 132.181L156.082 132.584C158.699 133.188 160.008 134.698 160.008 136.711C160.008 138.826 158.297 141.141 154.874 141.141C150.948 141.141 149.337 138.524 149.236 137.013L151.954 136.208Z",
              fill: white ? "#fff" : "#273659"
            }
          ),
          /* @__PURE__ */ jsx27(
            "path",
            {
              d: "M164.035 120C165.243 120 166.149 121.007 166.149 122.114C166.149 123.221 165.142 124.228 164.035 124.228C162.928 124.228 161.921 123.322 161.921 122.114C161.921 120.906 162.928 120 164.035 120ZM162.424 140.638V126.946H165.646V140.638H162.424Z",
              fill: white ? "#fff" : "#273659"
            }
          ),
          /* @__PURE__ */ jsx27(
            "path",
            {
              d: "M173.297 126.946H176.116V129.866H173.297V136.208C173.297 137.416 173.8 137.92 175.008 137.92C175.411 137.92 176.015 137.819 176.116 137.819V140.537C175.914 140.638 175.31 140.839 174.102 140.839C171.586 140.839 169.975 139.329 169.975 136.711V129.866H167.458V126.946H168.163C169.673 126.946 170.277 126.04 170.277 124.832V122.819H173.196V126.946H173.297Z",
              fill: white ? "#fff" : "#273659"
            }
          ),
          /* @__PURE__ */ jsx27(
            "path",
            {
              d: "M190.511 136.712C189.807 139.128 187.592 141.04 184.27 141.04C180.545 141.04 177.223 138.322 177.223 133.691C177.223 129.363 180.444 126.443 183.867 126.443C188.095 126.443 190.612 129.262 190.612 133.591C190.612 134.094 190.511 134.597 190.511 134.597H180.444C180.545 136.712 182.156 138.222 184.169 138.222C186.182 138.222 187.189 137.215 187.592 135.806L190.511 136.712ZM187.39 132.282C187.29 130.671 186.283 129.262 183.968 129.262C181.954 129.262 180.746 130.873 180.645 132.282H187.39Z",
              fill: white ? "#fff" : "#273659"
            }
          ),
          /* @__PURE__ */ jsx27(
            "path",
            {
              d: "M197.457 126.946H200.276V129.866H197.457V136.208C197.457 137.416 197.96 137.92 199.169 137.92C199.571 137.92 200.175 137.819 200.276 137.819V140.537C200.075 140.638 199.471 140.839 198.262 140.839C195.746 140.839 194.135 139.329 194.135 136.711V129.866H191.618V126.946H192.323C193.833 126.946 194.437 126.04 194.437 124.832V122.819H197.356V126.946H197.457Z",
              fill: white ? "#fff" : "#273659"
            }
          ),
          /* @__PURE__ */ jsx27(
            "path",
            {
              d: "M214.773 136.712C214.068 139.128 211.853 141.04 208.531 141.04C204.807 141.04 201.484 138.322 201.484 133.691C201.484 129.363 204.706 126.443 208.129 126.443C212.357 126.443 214.874 129.262 214.874 133.591C214.874 134.094 214.773 134.597 214.773 134.597H204.706C204.807 136.712 206.417 138.222 208.431 138.222C210.444 138.222 211.451 137.215 211.853 135.806L214.773 136.712ZM211.652 132.282C211.551 130.671 210.545 129.262 208.229 129.262C206.216 129.262 205.008 130.873 204.907 132.282H211.652Z",
              fill: white ? "#fff" : "#273659"
            }
          ),
          /* @__PURE__ */ jsx27(
            "path",
            {
              d: "M221.719 126.946H224.538V129.866H221.719V136.208C221.719 137.416 222.222 137.92 223.43 137.92C223.833 137.92 224.437 137.819 224.538 137.819V140.537C224.336 140.638 223.732 140.839 222.524 140.839C220.007 140.839 218.397 139.329 218.397 136.711V129.866H215.88V126.946H216.585C218.095 126.946 218.699 126.04 218.699 124.832V122.819H221.618V126.946H221.719Z",
              fill: white ? "#fff" : "#273659"
            }
          )
        ]
      }
    );
  }
  if (text === "horizontal") {
    return /* @__PURE__ */ jsxs19(
      "svg",
      {
        viewBox: "0 0 377 70",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
        ...props,
        children: [
          /* @__PURE__ */ jsx27(
            "path",
            {
              d: "M85.0732 48.4717V22.3208H101.363V26.5472H89.4436V33.9434H100.171V38.0378H89.4436V48.6038H85.0732V48.4717Z",
              fill: white ? "#fff" : "#273659"
            }
          ),
          /* @__PURE__ */ jsx27(
            "path",
            {
              d: "M121.361 39.4906C121.361 45.0378 117.388 49.0001 111.958 49.0001C106.528 49.0001 102.688 45.0378 102.688 39.4906C102.688 33.9435 106.661 29.9812 111.958 29.9812C117.388 29.9812 121.361 33.9435 121.361 39.4906ZM117.123 39.4906C117.123 35.7925 114.739 33.8114 112.09 33.8114C109.442 33.8114 107.058 35.7925 107.058 39.4906C107.058 43.1887 109.442 45.1699 112.09 45.1699C114.739 45.1699 117.123 43.3208 117.123 39.4906Z",
              fill: white ? "#fff" : "#273659"
            }
          ),
          /* @__PURE__ */ jsx27(
            "path",
            {
              d: "M124.804 48.4717V21.7925H129.042V48.4717H124.804Z",
              fill: white ? "#fff" : "#273659"
            }
          ),
          /* @__PURE__ */ jsx27(
            "path",
            {
              d: "M142.285 38.1698L149.834 48.6038H144.537L139.239 41.2076L136.988 43.5849V48.6038H132.75V21.7925H136.988V37.7736L143.875 30.5095H149.569L142.285 38.1698Z",
              fill: white ? "#fff" : "#273659"
            }
          ),
          /* @__PURE__ */ jsx27(
            "path",
            {
              d: "M167.316 43.3208C166.389 46.4906 163.475 49.0001 159.105 49.0001C154.204 49.0001 149.834 45.434 149.834 39.3586C149.834 33.6793 154.072 29.8491 158.575 29.8491C164.137 29.8491 167.448 33.5472 167.448 39.2265C167.448 39.8869 167.316 40.5472 167.316 40.5472H154.072C154.204 43.3208 156.323 45.302 158.972 45.302C161.621 45.302 162.945 43.9812 163.475 42.1321L167.316 43.3208ZM163.21 37.5095C163.078 35.3963 161.753 33.5472 158.707 33.5472C156.059 33.5472 154.469 35.6604 154.337 37.5095H163.21Z",
              fill: white ? "#fff" : "#273659"
            }
          ),
          /* @__PURE__ */ jsx27(
            "path",
            {
              d: "M177.248 49.0001C173.01 49.0001 170.494 45.8303 170.494 41.868V30.5095H174.732V41.0756C174.732 43.3208 175.792 45.1699 178.308 45.1699C180.824 45.1699 182.149 43.4529 182.149 41.2076V30.5095H186.387V45.1699C186.387 46.6227 186.519 47.8114 186.519 48.4718H182.413C182.281 48.0756 182.281 47.151 182.281 46.4907C181.486 48.0756 179.367 49.0001 177.248 49.0001Z",
              fill: white ? "#fff" : "#273659"
            }
          ),
          /* @__PURE__ */ jsx27(
            "path",
            {
              d: "M195.128 48.4718H190.89V30.5095H195.128V32.8869C196.32 30.7737 198.439 29.9812 200.425 29.9812C204.795 29.9812 206.914 33.151 206.914 37.1133V48.4718H202.676V37.7737C202.676 35.5284 201.617 33.8114 198.968 33.8114C196.584 33.8114 195.26 35.6604 195.26 38.0378V48.4718H195.128Z",
              fill: white ? "#fff" : "#273659"
            }
          ),
          /* @__PURE__ */ jsx27(
            "path",
            {
              d: "M213.139 21.3962C214.728 21.3962 215.92 22.717 215.92 24.1698C215.92 25.6227 214.595 26.9434 213.139 26.9434C211.682 26.9434 210.357 25.7547 210.357 24.1698C210.357 22.5849 211.682 21.3962 213.139 21.3962ZM211.02 48.4717V30.5094H215.258V48.4717H211.02Z",
              fill: white ? "#fff" : "#273659"
            }
          ),
          /* @__PURE__ */ jsx27(
            "path",
            {
              d: "M229.164 48.4718H224.926L217.642 30.5095H222.409L227.177 43.3208L231.812 30.5095H236.315L229.164 48.4718Z",
              fill: white ? "#fff" : "#273659"
            }
          ),
          /* @__PURE__ */ jsx27(
            "path",
            {
              d: "M253.929 43.3208C253.002 46.4906 250.088 49.0001 245.718 49.0001C240.818 49.0001 236.447 45.434 236.447 39.3586C236.447 33.6793 240.685 29.8491 245.188 29.8491C250.75 29.8491 254.061 33.5472 254.061 39.2265C254.061 39.8869 253.929 40.5472 253.929 40.5472H240.685C240.818 43.3208 242.937 45.302 245.585 45.302C248.234 45.302 249.558 43.9812 250.088 42.1321L253.929 43.3208ZM249.823 37.5095C249.691 35.3963 248.367 33.5472 245.321 33.5472C242.672 33.5472 241.083 35.6604 240.95 37.5095H249.823Z",
              fill: white ? "#fff" : "#273659"
            }
          ),
          /* @__PURE__ */ jsx27(
            "path",
            {
              d: "M268.1 34.7359C267.57 34.6039 267.173 34.6039 266.643 34.6039C263.332 34.6039 261.743 36.585 261.743 39.8869V48.4718H257.505V30.5095H261.743V33.4152C262.537 31.434 264.656 30.3774 266.908 30.3774C267.438 30.3774 267.835 30.5095 268.1 30.5095V34.7359Z",
              fill: white ? "#fff" : "#273659"
            }
          ),
          /* @__PURE__ */ jsx27(
            "path",
            {
              d: "M273.265 42.6604C273.398 44.2454 274.59 45.6982 276.973 45.6982C278.695 45.6982 279.622 44.7737 279.622 43.585C279.622 42.6604 278.96 41.868 277.503 41.6038L274.854 41.0755C271.676 40.4152 269.954 38.302 269.954 35.7925C269.954 32.6227 272.868 29.9812 276.576 29.9812C281.609 29.9812 283.198 33.2831 283.595 35.0001L280.02 36.0567C279.887 35.0001 279.092 33.4152 276.709 33.4152C275.252 33.4152 274.06 34.3397 274.06 35.5284C274.06 36.4529 274.722 37.2454 275.914 37.3774L278.695 37.9057C282.139 38.6982 283.86 40.6793 283.86 43.3208C283.86 46.0944 281.609 49.1321 277.106 49.1321C271.941 49.1321 269.822 45.6982 269.689 43.7171L273.265 42.6604Z",
              fill: white ? "#fff" : "#273659"
            }
          ),
          /* @__PURE__ */ jsx27(
            "path",
            {
              d: "M289.157 21.3962C290.746 21.3962 291.938 22.717 291.938 24.1698C291.938 25.6227 290.614 26.9434 289.157 26.9434C287.7 26.9434 286.376 25.7547 286.376 24.1698C286.376 22.5849 287.7 21.3962 289.157 21.3962ZM287.038 48.4717V30.5094H291.276V48.4717H287.038Z",
              fill: white ? "#fff" : "#273659"
            }
          ),
          /* @__PURE__ */ jsx27(
            "path",
            {
              d: "M301.341 30.5093H305.05V34.3395H301.341V42.6603C301.341 44.2452 302.004 44.9056 303.593 44.9056C304.123 44.9056 304.917 44.7735 305.05 44.7735V48.3395C304.785 48.4716 303.99 48.7357 302.401 48.7357C299.09 48.7357 296.971 46.7546 296.971 43.3207V34.3395H293.66V30.5093H294.587C296.574 30.5093 297.368 29.3207 297.368 27.7357V25.0942H301.209V30.5093H301.341Z",
              fill: white ? "#fff" : "#273659"
            }
          ),
          /* @__PURE__ */ jsx27(
            "path",
            {
              d: "M323.988 43.3206C323.061 46.4904 320.148 48.9998 315.777 48.9998C310.877 48.9998 306.507 45.4338 306.507 39.3583C306.507 33.6791 310.745 29.8489 315.248 29.8489C320.81 29.8489 324.121 33.547 324.121 39.2262C324.121 39.8866 323.988 40.547 323.988 40.547H310.745C310.877 43.3206 312.996 45.3017 315.645 45.3017C318.294 45.3017 319.618 43.981 320.148 42.1319L323.988 43.3206ZM319.883 37.5093C319.75 35.396 318.426 33.547 315.38 33.547C312.731 33.547 311.142 35.6602 311.01 37.5093H319.883Z",
              fill: white ? "#fff" : "#273659"
            }
          ),
          /* @__PURE__ */ jsx27(
            "path",
            {
              d: "M333.127 30.5093H336.835V34.3395H333.127V42.6603C333.127 44.2452 333.789 44.9056 335.378 44.9056C335.908 44.9056 336.702 44.7735 336.835 44.7735V48.3395C336.57 48.4716 335.775 48.7357 334.186 48.7357C330.875 48.7357 328.756 46.7546 328.756 43.3207V34.3395H325.445V30.5093H326.372C328.359 30.5093 329.154 29.3207 329.154 27.7357V25.0942H332.994V30.5093H333.127Z",
              fill: white ? "#fff" : "#273659"
            }
          ),
          /* @__PURE__ */ jsx27(
            "path",
            {
              d: "M355.905 43.3206C354.978 46.4904 352.065 48.9998 347.694 48.9998C342.794 48.9998 338.424 45.4338 338.424 39.3583C338.424 33.6791 342.662 29.8489 347.165 29.8489C352.727 29.8489 356.038 33.547 356.038 39.2262C356.038 39.8866 355.905 40.547 355.905 40.547H342.662C342.794 43.3206 344.913 45.3017 347.562 45.3017C350.211 45.3017 351.535 43.981 352.065 42.1319L355.905 43.3206ZM351.8 37.5093C351.667 35.396 350.343 33.547 347.297 33.547C344.648 33.547 343.059 35.6602 342.927 37.5093H351.8Z",
              fill: white ? "#fff" : "#273659"
            }
          ),
          /* @__PURE__ */ jsx27(
            "path",
            {
              d: "M365.044 30.5093H368.752V34.3395H365.044V42.6603C365.044 44.2452 365.706 44.9056 367.295 44.9056C367.825 44.9056 368.619 44.7735 368.752 44.7735V48.3395C368.487 48.4716 367.692 48.7357 366.103 48.7357C362.792 48.7357 360.673 46.7546 360.673 43.3207V34.3395H357.362V30.5093H358.289C360.276 30.5093 361.071 29.3207 361.071 27.7357V25.0942H364.911V30.5093H365.044Z",
              fill: white ? "#fff" : "#273659"
            }
          ),
          /* @__PURE__ */ jsx27(
            "path",
            {
              d: "M54.6121 10.434C56.2013 10.566 56.3338 10.6981 56.3338 10.6981L54.3472 7H37.6602H20.9733L19.1191 10.6981C19.1191 10.6981 19.2516 10.566 20.8408 10.434C34.2169 9.24528 35.9386 16.5094 36.2034 22.717C36.3359 26.4151 36.3359 29.4528 36.3359 29.4528H37.6602H38.9846C38.9846 29.4528 38.9846 26.4151 39.117 22.717C39.5143 16.5094 41.236 9.24528 54.6121 10.434Z",
              fill: white ? "#fff" : "#FF3F2E"
            }
          ),
          /* @__PURE__ */ jsx27(
            "path",
            {
              d: "M46.931 24.3019C49.7122 24.3019 51.9636 22.0566 51.9636 19.283C51.9636 16.5094 49.7122 14.2642 46.931 14.2642C44.1499 14.2642 41.8984 16.5094 41.8984 19.283C41.8984 22.0566 44.1499 24.3019 46.931 24.3019Z",
              fill: white ? "#fff" : "#FF3F2E"
            }
          ),
          /* @__PURE__ */ jsx27(
            "path",
            {
              d: "M28.39 24.3019C31.1712 24.3019 33.4226 22.0566 33.4226 19.283C33.4226 16.5094 31.1712 14.2642 28.39 14.2642C25.6088 14.2642 23.3574 16.5094 23.3574 19.283C23.3574 22.0566 25.6088 24.3019 28.39 24.3019Z",
              fill: white ? "#fff" : "#FF3F2E"
            }
          ),
          /* @__PURE__ */ jsx27(
            "path",
            {
              d: "M54.7451 21.7925V48.6038H69.578L58.056 21.7925H54.7451Z",
              fill: white ? "#fff" : "#FF3F2E"
            }
          ),
          /* @__PURE__ */ jsx27(
            "path",
            {
              d: "M20.5764 21.7925V48.6038H5.87598L17.3979 21.7925H20.5764Z",
              fill: white ? "#fff" : "#FF3F2E"
            }
          ),
          /* @__PURE__ */ jsx27(
            "path",
            {
              d: "M54.7446 48.6038C54.7446 48.6038 54.7446 66.4339 37.6603 66.4339C19.7814 66.4339 20.4436 48.6038 20.4436 48.6038C20.4436 48.6038 26.0059 54.1509 37.6603 54.1509C49.712 54.1509 54.7446 48.6038 54.7446 48.6038Z",
              fill: white ? "#fff" : "#FF3F2E"
            }
          )
        ]
      }
    );
  }
  return /* @__PURE__ */ jsxs19(
    "svg",
    {
      viewBox: "0 0 116 120",
      fill: "none",
      xmlns: "http://www.w3.org/2000/svg",
      ...props,
      children: [
        /* @__PURE__ */ jsx27(
          "path",
          {
            d: "M87.4185 17.8868C90.1355 18.1132 90.3619 18.3396 90.3619 18.3396L86.9657 12H58.4374H29.9091L26.7393 18.3396C26.7393 18.3396 26.9657 18.1132 29.6827 17.8868C52.5506 15.8491 55.494 28.3019 55.9468 38.9434C56.1732 45.283 56.1732 50.4906 56.1732 50.4906H58.4374H60.7015C60.7015 50.4906 60.7015 45.283 60.9279 38.9434C61.6072 28.3019 64.5506 15.8491 87.4185 17.8868Z",
            fill: white ? "#fff" : "#FF3F2E"
          }
        ),
        /* @__PURE__ */ jsx27(
          "path",
          {
            d: "M74.2864 41.6604C79.0411 41.6604 82.8902 37.8114 82.8902 33.0567C82.8902 28.3019 79.0411 24.4529 74.2864 24.4529C69.5317 24.4529 65.6826 28.3019 65.6826 33.0567C65.6826 37.8114 69.5317 41.6604 74.2864 41.6604Z",
            fill: white ? "#fff" : "#FF3F2E"
          }
        ),
        /* @__PURE__ */ jsx27(
          "path",
          {
            d: "M42.5881 41.6604C47.3429 41.6604 51.1919 37.8114 51.1919 33.0567C51.1919 28.3019 47.3429 24.4529 42.5881 24.4529C37.8334 24.4529 33.9844 28.3019 33.9844 33.0567C33.9844 37.8114 37.8334 41.6604 42.5881 41.6604Z",
            fill: white ? "#fff" : "#FF3F2E"
          }
        ),
        /* @__PURE__ */ jsx27(
          "path",
          {
            d: "M87.6445 37.3584V83.3207H113.003L93.3049 37.3584H87.6445Z",
            fill: white ? "#fff" : "#FF3F2E"
          }
        ),
        /* @__PURE__ */ jsx27(
          "path",
          {
            d: "M29.2297 37.3584V83.3207H4.09766L23.7958 37.3584H29.2297Z",
            fill: white ? "#fff" : "#FF3F2E"
          }
        ),
        /* @__PURE__ */ jsx27(
          "path",
          {
            d: "M87.6449 83.3208C87.6449 83.3208 87.6449 113.887 58.4374 113.887C27.8714 113.887 29.0034 83.3208 29.0034 83.3208C29.0034 83.3208 38.5129 92.8302 58.4374 92.8302C79.0412 92.8302 87.6449 83.3208 87.6449 83.3208Z",
            fill: white ? "#fff" : "#FF3F2E"
          }
        )
      ]
    }
  );
}

// src/components/paragraph/Paragraph.tsx
import clsx23 from "clsx";
import { jsx as jsx28 } from "react/jsx-runtime";
function P({
  size = "base",
  children,
  className,
  ...props
}) {
  return /* @__PURE__ */ jsx28(
    "p",
    {
      className: clsx23(
        "font-inter",
        "leading-loose",
        {
          ["body-7xl"]: size === "7xl",
          ["body-6xl"]: size === "6xl",
          ["body-5xl"]: size === "5xl",
          ["body-4xl"]: size === "4xl",
          ["body-3xl"]: size === "3xl",
          ["body-2xl"]: size === "2xl",
          ["body-xl"]: size === "xl",
          ["body-lg"]: size === "lg",
          ["body-md"]: size === "md",
          ["body-base"]: size === "base",
          ["body-sm"]: size === "sm",
          ["body-xs"]: size === "xs",
          ["body-xxs"]: size === "xxs"
        },
        className
      ),
      ...props,
      children
    }
  );
}

// src/components/progressbar/ProgressBar.tsx
import clsx24 from "clsx";
import { jsx as jsx29, jsxs as jsxs20 } from "react/jsx-runtime";
function ProgressBar({
  size = "lg",
  inline,
  progress = 0,
  barWidth,
  className,
  ...props
}) {
  return /* @__PURE__ */ jsxs20(
    "div",
    {
      className: clsx24(
        "relative",
        "bg-neutral-40",
        "overflow-hidden",
        "rounded-full",
        {
          "inline-block w-32": inline,
          block: !inline,
          "h-0.5": size === "sm",
          "h-2": size !== "sm"
        },
        className
      ),
      children: [
        /* @__PURE__ */ jsx29(
          "div",
          {
            className: clsx24("relative", "rounded-full", "bg-neutral-70", {
              "h-0.5": size === "sm",
              "h-2": size !== "sm"
            }),
            style: typeof barWidth === "number" && barWidth > 0 ? {
              width: `${barWidth * 100}%`,
              left: `${(1 - barWidth) * progress * 100}%`
            } : {
              width: `${progress * 100}%`,
              left: 0
            },
            "aria-hidden": true
          }
        ),
        /* @__PURE__ */ jsxs20(
          "progress",
          {
            className: "absolute opacity-0",
            "aria-valuemin": 0,
            "aria-valuemax": 1,
            "aria-valuenow": progress,
            max: 1,
            value: progress,
            ...props,
            children: [
              progress * 100,
              "%"
            ]
          }
        )
      ]
    }
  );
}

// src/components/slider-arrows/SliderArrows.tsx
import clsx25 from "clsx";
import ArrowLeft4 from "@fu/icons/ArrowLeft";
import ArrowRight3 from "@fu/icons/ArrowRight";
import { jsx as jsx30, jsxs as jsxs21 } from "react/jsx-runtime";
function SliderArrows({
  alignment = "left",
  current = 1,
  count,
  children,
  className,
  onPrevClick,
  onNextClick,
  ...props
}) {
  if (!count)
    return null;
  const cssClass = clsx25(
    "inline-flex items-center gap-6",
    alignment === "right" && "flex-row-reverse",
    className
  );
  const prevDisabled = current <= 1;
  const nextDisabled = current >= count;
  return /* @__PURE__ */ jsxs21("div", { className: cssClass, ...props, children: [
    /* @__PURE__ */ jsxs21("span", { className: "meta-base font-medium", children: [
      Math.max(1, Math.min(current, count)),
      " / ",
      count
    ] }),
    /* @__PURE__ */ jsxs21("div", { children: [
      /* @__PURE__ */ jsx30(
        IconButton,
        {
          size: "lg",
          rounded: true,
          className: "mr-2",
          disabled: prevDisabled,
          onClick: onPrevClick,
          children: /* @__PURE__ */ jsx30(ArrowLeft4, {})
        }
      ),
      /* @__PURE__ */ jsx30(
        IconButton,
        {
          size: "lg",
          rounded: true,
          disabled: nextDisabled,
          onClick: onNextClick,
          children: /* @__PURE__ */ jsx30(ArrowRight3, {})
        }
      )
    ] })
  ] });
}

// src/components/tabs/Tabs.tsx
import { Children as Children2, isValidElement as isValidElement7 } from "react";

// src/components/tabs/TabsContext.tsx
import { createContext as createContext2, useId as useId9, useReducer as useReducer2, useContext as useContext2 } from "react";
import { jsx as jsx31 } from "react/jsx-runtime";
var initialState2 = {
  currentTab: "initial",
  id: "foobar"
};
var TabsContext = createContext2({});
function TabsReducer(state = initialState2, { type, payload }) {
  switch (type) {
    case "set":
      return {
        ...state,
        currentTab: payload
      };
    default:
      throw new Error(`Unhandled action type: ${type}`);
  }
}
function TabsProvider({ initial, children }) {
  const id = useId9();
  const [state, dispatch] = useReducer2(TabsReducer, {
    ...initialState2,
    id,
    ...initial ? { currentTab: initial } : {}
  });
  return /* @__PURE__ */ jsx31(TabsContext.Provider, { value: { state, dispatch }, children });
}
function useTabs() {
  const context = useContext2(TabsContext);
  if (context === void 0) {
    throw new Error("useTabs must be used within a TabsProvider");
  }
  return context;
}

// src/components/tabs/TabsList.tsx
import clsx27 from "clsx";

// src/components/tabs/TabsListItem.tsx
import clsx26 from "clsx";
import slugify2 from "slugify";
import { jsx as jsx32, jsxs as jsxs22 } from "react/jsx-runtime";
function TabsListItem({ label, metaText }) {
  const {
    state: { currentTab, id },
    dispatch
  } = useTabs();
  const isActive = currentTab === label;
  const cssClass = clsx26("px-4 pt-2 pb-4 relative ");
  const handleClick = (label2) => {
    dispatch({
      type: "set",
      payload: label2
    });
  };
  return /* @__PURE__ */ jsxs22(
    "button",
    {
      className: cssClass,
      onClick: () => handleClick(label),
      role: "tab",
      id: `${id}_tab_${slugify2(label)}`,
      "aria-selected": isActive,
      "aria-controls": `${id}_panel_${slugify2(label)}`,
      children: [
        /* @__PURE__ */ jsx32("span", { className: "cap-base whitespace-nowrap text-neutral", children: label }),
        metaText && /* @__PURE__ */ jsx32("span", { className: "meta-sm ml-2 text-neutral-50", children: metaText }),
        isActive && /* @__PURE__ */ jsx32(
          "span",
          {
            className: clsx26(
              "absolute top-full right-0 left-0",
              "h-0.5",
              "bg-primary-50",
              "rounded-full"
            )
          }
        )
      ]
    }
  );
}

// src/components/tabs/TabsList.tsx
import { jsx as jsx33, jsxs as jsxs23 } from "react/jsx-runtime";
function TabsList({ items, className }) {
  const cssClass = clsx27(
    "w-full md:w-auto overflow-hidden overflow-x-auto mt-6 md:mt-0",
    className
  );
  return /* @__PURE__ */ jsxs23("div", { className: cssClass, children: [
    /* @__PURE__ */ jsx33("div", { className: "flex", role: "tablist", children: items.map((i) => /* @__PURE__ */ jsx33(TabsListItem, { ...i }, i.label)) }),
    /* @__PURE__ */ jsx33("div", { className: "h-0.5 rounded-full bg-neutral-40" })
  ] });
}
var TabsList_default = TabsList;

// src/components/tabs/Tabs.tsx
import { jsx as jsx34, jsxs as jsxs24 } from "react/jsx-runtime";
function TabsInner({ title, subtitle, children }) {
  const arrayChildren = Children2.toArray(children);
  const items = arrayChildren.map((c) => {
    if (isValidElement7(c)) {
      return {
        label: c.props.label,
        metaText: c.props.metaText
      };
    }
    return void 0;
  }).filter((c) => c);
  return /* @__PURE__ */ jsxs24("section", { className: "bg-neutral-30", children: [
    /* @__PURE__ */ jsxs24("header", { className: "flex flex-col items-start justify-between md:flex-row md:items-end", children: [
      /* @__PURE__ */ jsxs24("div", { children: [
        /* @__PURE__ */ jsx34("span", { className: "hd-lg block md:hd-xl", children: title }),
        /* @__PURE__ */ jsx34("span", { className: "body-sm", children: subtitle })
      ] }),
      /* @__PURE__ */ jsx34(TabsList_default, { items })
    ] }),
    /* @__PURE__ */ jsx34("div", { className: "mt-10", children })
  ] });
}
function Tabs({ initial, ...props }) {
  return /* @__PURE__ */ jsx34(TabsProvider, { initial, children: /* @__PURE__ */ jsx34(TabsInner, { ...props }) });
}

// src/components/tabs/TabsContent.tsx
import clsx28 from "clsx";
import slugify3 from "slugify";
import { jsx as jsx35 } from "react/jsx-runtime";
function TabsContent({
  children,
  className,
  ...props
}) {
  const {
    state: { currentTab, id }
  } = useTabs();
  const { label } = props;
  const isActive = currentTab == label;
  const labelSlug = slugify3(label);
  const cssClass = clsx28(!isActive && "hidden", className);
  return /* @__PURE__ */ jsx35(
    "div",
    {
      role: "tabpanel",
      ...props,
      className: cssClass,
      id: `${id}_panel_${labelSlug}`,
      "aria-hidden": !isActive,
      "aria-labelledby": `${id}_tab_${labelSlug}`,
      children
    }
  );
}

// src/components/tags/Tag.tsx
import clsx29 from "clsx";
import Close3 from "@fu/icons/Close";
import { jsx as jsx36, jsxs as jsxs25 } from "react/jsx-runtime";
function Tag({
  active = false,
  showClose = false,
  size = "sm",
  children,
  className,
  ...props
}) {
  return /* @__PURE__ */ jsxs25(
    "button",
    {
      "aria-pressed": active,
      className: clsx29(
        "relative",
        "py-3 px-6",
        "items-center",
        "rounded-full border-2",
        {
          "border-neutral-50 bg-neutral-0": !active,
          "border-neutral-60 bg-neutral-40": active,
          "cap-sm": size === "sm",
          "cap-md": size === "md"
        },
        className
      ),
      ...props,
      children: [
        active && showClose && /* @__PURE__ */ jsx36(
          "span",
          {
            className: clsx29("absolute", "top-1/2", "translate-y-[-50%]", {
              ["right-3"]: size === "md",
              ["right-2"]: size === "sm"
            }),
            children: /* @__PURE__ */ jsx36(Close3, { className: "mx-1" })
          }
        ),
        children
      ]
    }
  );
}

// src/components/tags/TagGroup.tsx
import clsx30 from "clsx";
import { jsx as jsx37 } from "react/jsx-runtime";
function TagGroup({
  children,
  className,
  ...props
}) {
  return /* @__PURE__ */ jsx37("div", { className: clsx30("flex gap-2", className), ...props, children });
}

// src/hooks/use-is-mounted.ts
import { useRef as useRef8, useEffect as useEffect3 } from "react";
function useIsMounted() {
  const isMounted = useRef8(false);
  useEffect3(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);
  return isMounted;
}

// src/hooks/use-window-size.ts
import { useState as useState5, useEffect as useEffect4 } from "react";
function isBrowser2() {
  return !!(typeof window !== "undefined" && window.document && window.document.createElement);
}
function useWindowSize() {
  const [windowSize, setWindowSize] = useState5({
    width: void 0,
    height: void 0
  });
  useEffect4(() => {
    if (!isBrowser2())
      return;
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return windowSize;
}
export {
  Accordion,
  AccordionContent,
  Alert,
  Anchor,
  Badge,
  BreadcrumbItem,
  BreadcrumbText,
  Breadcrumbs,
  Button,
  Checkbox,
  CheckboxWrapper,
  CourseCard,
  Drawer,
  Flag,
  H1,
  H2,
  H3,
  H4,
  H5,
  H6,
  Heading,
  IconButton,
  Input,
  List,
  ListItem,
  Loader,
  Logo,
  P,
  ProgressBar,
  Radio,
  Select,
  SliderArrows,
  Spinner,
  Tabs,
  TabsContent,
  TabsInner,
  Tag,
  TagGroup,
  Toggle,
  Tooltip,
  TooltipCore,
  TooltipPortal,
  TooltipTarget,
  useIsMounted,
  useWindowSize
};
