// src/icons/share.svg
import * as React from "react";
import { jsx, jsxs } from "react/jsx-runtime";
var SvgShare = ({ title, titleId, ...props }) => /* @__PURE__ */ jsxs(
  "svg",
  {
    width: "1em",
    height: "1em",
    viewBox: "0 0 24 24",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    "aria-labelledby": titleId,
    ...props,
    children: [
      title ? /* @__PURE__ */ jsx("title", { id: titleId, children: title }) : null,
      /* @__PURE__ */ jsx(
        "path",
        {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d: "M4 11a1 1 0 0 1 1 1v8a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-8a1 1 0 1 1 2 0v8a3 3 0 0 1-3 3H6a3 3 0 0 1-3-3v-8a1 1 0 0 1 1-1ZM11.293 1.293a1 1 0 0 1 1.414 0l4 4a1 1 0 0 1-1.414 1.414L12 3.414 8.707 6.707a1 1 0 0 1-1.414-1.414l4-4Z",
          fill: "currentColor"
        }
      ),
      /* @__PURE__ */ jsx(
        "path",
        {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d: "M12 1a1 1 0 0 1 1 1v13a1 1 0 1 1-2 0V2a1 1 0 0 1 1-1Z",
          fill: "currentColor"
        }
      )
    ]
  }
);

export {
  SvgShare
};
