// src/icons/unlock.svg
import * as React from "react";
import { jsx, jsxs } from "react/jsx-runtime";
var SvgUnlock = ({ title, titleId, ...props }) => /* @__PURE__ */ jsxs(
  "svg",
  {
    width: "1em",
    height: "1em",
    viewBox: "0 0 24 24",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    "aria-labelledby": titleId,
    ...props,
    children: [
      title ? /* @__PURE__ */ jsx("title", { id: titleId, children: title }) : null,
      /* @__PURE__ */ jsx(
        "path",
        {
          d: "M12 4c-1.648 0-3 1.352-3 3v3h9a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2v-8a2 2 0 0 1 2-2h1V7c0-2.752 2.248-5 5-5s5 2.248 5 5a1 1 0 1 1-2 0c0-1.648-1.352-3-3-3Zm-6 8v8h12v-8H6Z",
          fill: "currentColor"
        }
      )
    ]
  }
);

export {
  SvgUnlock
};
