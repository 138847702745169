// src/icons/shopping-cart.svg
import * as React from "react";
import { jsx, jsxs } from "react/jsx-runtime";
var SvgShoppingCart = ({ title, titleId, ...props }) => /* @__PURE__ */ jsxs(
  "svg",
  {
    width: "1em",
    height: "1em",
    viewBox: "0 0 24 24",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    "aria-labelledby": titleId,
    ...props,
    children: [
      title ? /* @__PURE__ */ jsx("title", { id: titleId, children: title }) : null,
      /* @__PURE__ */ jsx(
        "path",
        {
          d: "M4.142 4 6.01 16.136A1.003 1.003 0 0 0 7.016 17H18a1 1 0 0 0 .958-.713l3-10A1 1 0 0 0 21 5H6.32l-.33-2.138a.993.993 0 0 0-.346-.627.997.997 0 0 0-.66-.235H3a1 1 0 0 0 0 2h1.142Zm3.716 11-1.23-8h13.028l-2.4 8H7.858ZM10 20a2 2 0 1 1-4 0 2 2 0 0 1 4 0ZM19 20a2 2 0 1 1-4 0 2 2 0 0 1 4 0Z",
          fill: "currentColor"
        }
      )
    ]
  }
);

export {
  SvgShoppingCart
};
