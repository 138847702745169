// src/icons/icon.document.svg
import * as React from "react";
import { jsx, jsxs } from "react/jsx-runtime";
var SvgIcondocument = ({ title, titleId, ...props }) => /* @__PURE__ */ jsxs(
  "svg",
  {
    width: "1em",
    height: "1em",
    viewBox: "0 0 24 24",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    "aria-labelledby": titleId,
    ...props,
    children: [
      title ? /* @__PURE__ */ jsx("title", { id: titleId, children: title }) : null,
      /* @__PURE__ */ jsx(
        "path",
        {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d: "M3.501 4.762a2.75 2.75 0 0 1 2.75-2.75h8.487c.204 0 .4.083.541.23l5.065 5.276a.75.75 0 0 1 .209.52v11.077a2.75 2.75 0 0 1-2.75 2.75H6.251a2.75 2.75 0 0 1-2.75-2.75V4.762Zm2.75-1.25c-.69 0-1.25.56-1.25 1.25v14.353c0 .69.56 1.25 1.25 1.25h11.552c.69 0 1.25-.56 1.25-1.25V8.339l-4.635-4.827H6.251Z",
          fill: "currentColor"
        }
      ),
      /* @__PURE__ */ jsx(
        "path",
        {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d: "M14.5 2a.75.75 0 0 1 .75.75v4.5h4.5a.75.75 0 0 1 0 1.5H14.5a.75.75 0 0 1-.75-.75V2.75A.75.75 0 0 1 14.5 2ZM8 15.25a.75.75 0 0 1 .75-.75h6.5a.75.75 0 0 1 0 1.5h-6.5a.75.75 0 0 1-.75-.75ZM8 11.5a.75.75 0 0 1 .75-.75h3a.75.75 0 0 1 0 1.5h-3A.75.75 0 0 1 8 11.5Z",
          fill: "currentColor"
        }
      )
    ]
  }
);

export {
  SvgIcondocument
};
