// src/icons/heart.svg
import * as React from "react";
import { jsx, jsxs } from "react/jsx-runtime";
var SvgHeart = ({ title, titleId, ...props }) => /* @__PURE__ */ jsxs(
  "svg",
  {
    width: "1em",
    height: "1em",
    viewBox: "0 0 24 24",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    "aria-labelledby": titleId,
    ...props,
    children: [
      title ? /* @__PURE__ */ jsx("title", { id: titleId, children: title }) : null,
      /* @__PURE__ */ jsx(
        "path",
        {
          d: "M12 4.528a6 6 0 0 0-8.243 8.715l6.829 6.828a2 2 0 0 0 2.828 0l6.829-6.828A6 6 0 0 0 12 4.528Zm-1.172 1.644.465.464a1 1 0 0 0 1.414 0l.465-.464a4 4 0 1 1 5.656 5.656L12 18.657l-6.828-6.829a4 4 0 0 1 5.656-5.656Z",
          fill: "currentColor"
        }
      )
    ]
  }
);

export {
  SvgHeart
};
