// src/icons/copy.svg
import * as React from "react";
import { jsx, jsxs } from "react/jsx-runtime";
var SvgCopy = ({ title, titleId, ...props }) => /* @__PURE__ */ jsxs(
  "svg",
  {
    width: "1em",
    height: "1em",
    viewBox: "0 0 24 24",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    "aria-labelledby": titleId,
    ...props,
    children: [
      title ? /* @__PURE__ */ jsx("title", { id: titleId, children: title }) : null,
      /* @__PURE__ */ jsx(
        "path",
        {
          d: "M2 4a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v4h4a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H10a2 2 0 0 1-2-2v-4H4a2 2 0 0 1-2-2V4Zm8 12v4h10V10h-4v4a2 2 0 0 1-2 2h-4Zm4-2V4H4v10h10Z",
          fill: "currentColor"
        }
      )
    ]
  }
);

export {
  SvgCopy
};
